@import '../../../../theme/newVariables';

.container {
  background-color: $az-grey-1;
  padding: var(--st-unit-3);
  display: flex;
  align-items: center;

  @media #{$medium-and-up} {
    padding: var(--st-unit-4);
  }

  &:focus {
    background-color: $az-grey-1;
  }
}

.leftImageContainer {
  position: relative;
  width: 50px;
  height: 30px;

  @media #{$medium-and-up} {
    width: 75px;
    height: 49px;
  }

  @media #{$extra-large-and-up} {
    width: 83px;
    height: 57px;
  }
}

.rightImageContainer {
  position: relative;

  width: 35px;
  height: 35px;

  @media #{$medium-and-up} {
    width: 70px;
    height: 70px;
  }
}

.image {
  object-fit: contain;
}

.copyContainer {
  flex: 1;
  margin: 0 var(--st-unit-4) 0 var(--st-unit-4);
}

.installCopy {
  font-size: var(--st-font-size-087);
  @media #{$medium-and-up} {
    font-size: var(--st-font-size-112);
  }
}

.seeAllCopy {
  font-size: var(--st-font-size-075);
  @media #{$medium-and-up} {
    font-size: var(--st-font-size-087);
  }
}
