@import '@/theme/newVariables';

.relatedProductsContainer {
  margin: $az-spacing-60 $az-spacing-zero $az-spacing-m $az-spacing-zero;
  background-color: $az-grey-1;
  padding: var(--st-unit-5);
  min-height: 50px;

  .headerContainer {
    display: flex;
    font-family: $font-az-cond;
    font-weight: bold;

    @media #{$medium-and-up} {
      margin-bottom: var(--st-unit-4);
    }
  }

  .notificationContainer {
    margin-bottom: 40px;

    .notificationMessage {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .iconAndText {
        display: flex;
        align-items: center;

        .notificationTitle {
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
        }

        .notificationSubtitle {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
        }
      }

      .errorIcon::before {
        content: '';
        background-image: url('/images/notification/error.svg');
        background-size: $az-spacing-s $az-spacing-s;
        display: inline-block;
        width: $az-spacing-s;
        height: $az-spacing-s;
        background-repeat: no-repeat;
        vertical-align: middle;
        margin-right: $az-spacing-xxxs;
      }

      .closeNotificationButton {
        border: none;
        background-color: transparent;
        justify-self: flex-end;
      }
    }

    .errorShadow {
      box-shadow: $az-spacing-zero $az-spacing-zero $az-spacing-2 $az-spacing-zero
          rgba($az-black, 0.14),
        $az-spacing-zero $az-spacing-2 $az-spacing-2 $az-spacing-zero rgba($az-black, 0.12),
        $az-spacing-zero $az-spacing-1 $az-spacing-3 $az-spacing-zero rgba($az-black, 0.2),
        inset $az-spacing-5xs $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-color-error;
    }
  }

  .relatedPartsBody {
    display: flex;
    flex-direction: column;

    .relatedPartsPictureListContainer {
      display: flex;
    }

    .productQuantityInfo {
      display: flex;
      align-items: center;
      margin: 22px 0 var(--st-unit-5);
      font-size: 14px;
      column-gap: var(--st-unit-2);

      @media #{$medium-and-up} {
        margin-top: var(--st-unit-6);
      }

      .productQuantityInfoImageWrapper {
        display: flex;
      }

      .productQuantityInfoLabel {
        margin-left: 4px;
      }
    }
  }
}
