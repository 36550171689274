@use '@/theme/newVariables' as *;

tr.specInnerRow {
  text-align: left;

  @media #{$small-and-down} {
    display: flex;
    flex-wrap: wrap;
    padding: $az-spacing-4xs $az-spacing-xxs;
  }

  &:nth-child(even) {
    background: $az-grey-1;
  }
}

.specsCell {
  @media only screen and (max-width: #{$large-screen-up}) {
    padding: $az-spacing-xxs;
  }

  @media #{$small-and-down} {
    color: $az-grey-6;
    padding: $az-spacing-4xs $az-spacing-zero;
  }

  &.compactCell {
    @media only screen and (max-width: #{$large-screen-up}) {
      padding: $az-spacing-4xs $az-spacing-xxs;
    }

    @media #{$small-and-down} {
      padding: $az-spacing-zero;
    }
  }
}

th.specLabel {
  font-size: $font-size-16;
  width: 350px;

  @media only screen and (max-width: #{$large-screen-up}) {
    width: 100% * 1 / 3;
  }

  @media #{$small-and-down} {
    width: 100%;
  }
}

td.specsValue {
  @media only screen and (max-width: #{$large-screen-up}) {
    width: 100% * 2 / 3;
  }
}
