@import '../../theme/newVariables.scss';

.label {
  color: #6e6e6e;
  font-size: 12px;
}

.value {
  color: $az-black;
  font-size: 12px;
}

.labelContainer {
  display: flex;

  @media #{$medium-only} {
    font-size: $font-size-12;
  }
  @media #{$small-and-down} {
    font-size: $font-size-10;
  }
}
