@import '@/theme/newVariables.scss';

.page {
  display: flex;
  flex-direction: column;
}

.pageTitle,
.pageTitleMobile {
  font-family: 'AutoZoneCond-Heavy', Helvetica, sans-serif;
  font-size: 44px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--st-color-gray-700);
  text-transform: uppercase;
  margin-bottom: 0;
}

.pageDescription,
.pageDescriptionMobile {
  font-family: 'HelveticaNeue', sans-serif;
  font-size: var(--st-unit-4);
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--st-color-gray-700);
  margin-top: var(--st-unit-2);

  ul li {
    list-style-type: disc;
    margin-left: 24px;
  }

  ol {
    padding: 0px;
  }

  ol li {
    list-style-type: decimal;
    margin-left: 24px;
  }
}

.pageTitleMobile,
.pageDescriptionMobile {
  display: none;

  @media #{$medium-and-down} {
    display: block;
  }
}

.pageTitle,
.pageDescription {
  padding-top: 20px;

  @media #{$medium-and-down} {
    display: none;
  }
}

.container {
  display: flex;
  flex-direction: row;

  @media #{$medium-and-down} {
    flex-direction: column;
    width: 100%;
  }
}

.tableOfContentsContainer {
  margin: var(--st-unit-8) 32px 0 0;

  @media #{$medium-and-down} {
    margin: var(--st-unit-8) 0 0 0;
  }
}

.tableContainer {
  position: sticky;
  top: 100px;
  width: 375px;

  @media #{$medium-and-down} {
    flex-direction: column;
    width: 100%;
  }
}

.sectionHeader {
  padding-bottom: var(--st-unit-4);
  font-family: 'AutoZoneCond', Arial, Helvetica, sans-serif;
  font-size: var(--st-unit-5);
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--st-color-gray-700);
  text-transform: uppercase;
}

.mainTopicHeader {
  line-height: 1.86;

  span div {
    margin: 0px;
    padding: 8px;
  }

  div {
    padding: 3px 0px 3px 4px;
  }
}

.topicHeaderText {
  color: var(--st-color-black);
  text-decoration: none;
  font-family: 'HelveticaNeue-Bold', sans-serif;
  font-size: 16px;
  line-height: 24px;

  @media #{$small-and-down} {
    font-size: 14px;
    line-height: 20px;
  }
}

.noLinksSection span:nth-child(2) {
  svg {
    display: none;
  }

  width: 0;
}

.subsectionLinkContainer {
  align-self: center;
  width: 100%;
}

.subsectionLink {
  color: var(--Primary-Primary-Black, #6e6e6e);
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: var(--st-font-weight-regular);
  line-height: 22px;

  @media #{$small-and-down} {
    font-size: 12px;
    line-height: 20px;
  }
}

.subsectionLinkContainer:hover {
  background: var(--st-color-gray-100);
  transition: all 0.3s ease-in;
}

.contentContainer {
  display: flex;
  flex-direction: column;
}

.paragraphsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--st-unit-6);
}

.paragraphHeading {
  scroll-margin-top: $az-spacing-100;
  font-size: 28px;
  line-height: 36px;
  padding-top: 16px;

  @media #{$small-and-down} {
    font-size: 22px;
    line-height: 28px;
  }
}

.paragraph {
  scroll-margin-top: $az-spacing-100;
  flex-direction: column;
  display: flex;
  gap: var(--st-unit-6);
  padding-top: var(--st-unit-8);
  font-family: 'HelveticaNeue', sans-serif;
  font-size: 16px;
  font-style: normal;
  color: var(--st-color-gray-600);
  line-height: 24px;

  a {
    color: inherit;
    text-decoration: underline;
  }

  h2,
  h3,
  h4 {
    font-family: 'HelveticaNeue-Bold', sans-serif;
    font-size: var(--st-unit-4);
    line-height: 2;
    color: var(--st-color-gray-700);
    margin-bottom: var(--st-unit-3);
  }

  ul li {
    list-style-type: disc;
    margin-left: 24px;
  }

  ol {
    padding: 0px;
  }

  ol li {
    list-style-type: decimal;
    margin-left: 24px;
  }

  .tableWrapper {
    overflow-x: auto;
  }

  .tableWrapper td {
    min-width: min-content;
    max-width: 270px;
    white-space: normal;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
  }

  thead th {
    background-color: #e5e5e5;
    font-weight: bold;
    text-align: left;
    padding: 10px;
    border: 1px solid #fff;
    white-space: normal;
  }

  tbody td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }

  tbody tr:nth-child(even) {
    background-color: #e5e5e5;
  }

  tbody tr:nth-child(odd) {
    background-color: #fff;
  }

  tbody tr:nth-child(odd) td {
    border-color: #e5e5e5;
  }

  tbody tr:nth-child(even) td {
    border-color: #fff;
  }

  th,
  td {
    white-space: nowrap;
  }
}
