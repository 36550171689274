@import '@/theme/newVariables';

.bundleDealOption {
  padding: 16px 12px;
  border: solid 1px #979797;
  margin-bottom: 16px;
}

.mainDetails {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.dealImage {
  min-width: 90px;
  min-height: 90px;
  margin-right: 12px;
}

.price {
  margin-bottom: 6px;
}

.name {
  font-size: 12px;
  margin-top: -3px;
}

.description {
  font-size: 12px;
  color: #5b5d5b;
  margin: -4px 0 16px;
}

.expirationDate {
  font-size: 12px;
  color: #5b5d5b;
  margin-bottom: 16px;
}

.dealPriceWrapper {
  align-items: flex-start;
}
