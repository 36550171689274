.vehicleErrorContainer {
  background-color: var(--st-color-gray-100);
  padding: var(--st-unit-5) var(--st-unit-4);
}

.ctaContainer {
  text-align: right;
}

.ctaButton {
  width: fit-content;
}
