@import '@/theme/newVariables';

.dealsModalContent {
  margin-bottom: $az-spacing-s;
}

.headerSection {
  padding-bottom: $az-spacing-s;
}

.productTagImg {
  width: $az-spacing-4xl;
  height: $az-spacing-4xl;

  @media #{$small-and-down} {
    width: $az-spacing-68;
    height: $az-spacing-68;
  }
}

.verticalBar {
  width: $az-spacing-2;
  height: $az-spacing-xl;
  border: solid $az-spacing-1 $az-color-caution;
  margin-left: $az-spacing-47;
  margin-right: $az-spacing-27;

  @media #{$medium-only} {
    margin-left: $az-spacing-m;
    margin-right: $az-spacing-xxs;
  }
}

.prodDescPopup {
  font-size: $font-size-25;
  width: $az-percent-75;
  line-height: 1.28;

  @media #{$medium-and-down} {
    font-size: $font-size-16;
  }
}

.mainProduct {
  align-items: center;
}

.gridView {
  padding: $az-spacing-m;
  background-color: $az-grey-2;
}

@media #{$small-and-down} {
  .dealsModalContent {
    padding: $az-spacing-5xs;
  }

  .gridView {
    padding: $az-spacing-xs $az-spacing-4xs;
    background-color: $az-grey-1;
  }
}
