@import '@/theme/newVariables';

.bundleDetails,
.bundleConstraintPicker {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bundleDetailsScroller {
  height: 100%;
  overflow: auto;
}

.bundleDetailsHeader {
  padding: 16px;
  background-color: #f2f2f2;
}

.bundleDetailsCard {
  display: flex;
  background-color: white;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(32, 33, 36, 0.12), 0 1px 1px 0 rgba(32, 33, 36, 0.14),
    0 2px 1px -1px rgba(199, 205, 223, 0.2);
}

.bundleDetailsImage {
  min-width: 68px;
  min-height: 68px;
  margin-right: 12px;
}

.bundleDetailsPrice {
  margin-bottom: 6px;
}

.bundleDetailsName {
  font-size: 12px;
}

.bundleDetailsDescription {
  font-size: 12px;
  color: #5b5d5b;
  margin-bottom: 16px;
}

.separator {
  height: 1px;
  background-color: #e5e5e5;
  margin-top: 8px;
  margin-bottom: 16px;
}

.bundleDetailsBody {
  padding: 16px;
  flex: 1 1 auto;
}

.bundleConstraintBody {
  padding: 16px;
  flex: 1 1 auto;
  overflow: auto;
}

.bundleDetailsFooter {
  padding: 12px;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  width: 100%;

  button {
    width: 100%;
    text-transform: uppercase;

    &:focus {
      background-color: #202124;
    }

    @media #{$small-and-down} {
      &:hover {
        background-color: #202124 !important;
      }
    }
  }
}

.addToCartBtn {
  width: 100%;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin-top: 16px;
}

.section {
  margin-bottom: 16px;
}

.sectionTitle {
  font-size: 16px;
  font-weight: bold;
}

.sectionDescription {
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: 12px;
}

.constraintPickerCard {
  margin-bottom: 8px;
  cursor: pointer;
}

.constraintPickerPagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;

  > div {
    margin: 0;
  }
}

.currentVehicleSectionTitle {
  font-size: 12px;
  margin: 12px 0;
}

.selectedVehicleCard {
  background-color: white;
  padding: 10px 12px;
  box-shadow: 0 1px 3px 0 rgba(32, 33, 36, 0.12), 0 1px 1px 0 rgba(32, 33, 36, 0.14),
    0 2px 1px -1px rgba(199, 205, 223, 0.2);
  display: flex;
  align-items: center;
  border-left: 3px solid #f26d0d;
}

.selectedVehicleImg {
  flex: 0 0 auto;
  margin-right: 12px;
}

.selectedVehicleText {
  flex: 1 1 auto;
  font-size: 12px;
  font-weight: normal;
  color: #484848;
}

.noMargin {
  margin: 0 !important;
}

.parentSkuHeader {
  display: flex;
}

.parentSkuHeaderSectionTile {
  flex: 1 1 auto;
}

.errorContainer {
  margin-top: 12px;
}

.dealPriceWrapper {
  align-items: flex-start;
}

.constraintItemSwap {
  font-size: 14px;
}
