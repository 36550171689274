@import '@/theme/newVariables.scss';

.tooltipContainer {
  margin-bottom: -4px;
  margin-left: 4px;
}

@media #{$extra-large-and-up} {
  .xLargeOverride {
    --st-font-size-root: 16px;
    --st-font-size-100: var(--st-font-size-root, 1rem);
    --st-font-size-087: calc(0.875 * var(--st-font-size-100));

    line-height: 20px;
  }
}
