@import '@/theme/newVariables';

.bannerContainer {
  height: auto;
  box-shadow: $az-spacing-zero $az-spacing-1 $az-spacing-3 $az-spacing-zero rgba(0, 0, 0, 0.2),
    $az-spacing-zero $az-spacing-2 $az-spacing-2 $az-spacing-zero rgba(0, 0, 0, 0.12),
    $az-spacing-zero $az-spacing-zero $az-spacing-2 $az-spacing-zero rgba(0, 0, 0, 0.14);
  margin: $az-spacing-xs;
}

.breadCrumbContainer {
  @media #{$small-and-down} {
    margin-left: calc(var(--st-unit-4) * -1);
  }
}

.buyersGuideSummary {
  display: flex !important;
  outline: none;

  &.buyersGuideSummary {
    padding: $az-spacing-zero $az-spacing-xxs;
    align-items: flex-start;
  }
}

.bannerHeader {
  height: $az-spacing-50;
  color: $az-black;
  width: $az-percent-100;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  line-height: $az-spacing-25;
  text-transform: uppercase;
  letter-spacing: $az-spacing-three-fourths;

  .blackCar {
    margin: $az-spacing-zero $az-spacing-xs $az-spacing-zero;
    display: flex;
    justify-content: center;

    @media #{$small-and-down} {
      margin: $az-spacing-zero $az-spacing-xxs $az-spacing-zero;
    }
  }

  .arrow {
    margin-left: auto;
    margin-right: $az-spacing-s;
    display: flex;
    justify-content: center;

    @media #{$small-and-down} {
      margin-right: $az-spacing-xxs;
    }
  }

  .titleOne {
    font-family: $font-az-cond-heavy;
    font-size: $az-spacing-xs;
    margin-right: 0.25em;

    @media #{$small-and-down} {
      font-size: $az-spacing-15;
      line-height: $az-spacing-m;
      color: $az-black;
    }
  }

  .titleTwo {
    font-family: $font-az-cond-medium;
    font-size: $az-spacing-xs;

    @media #{$small-and-down} {
      font-size: $az-spacing-15;
      line-height: $az-spacing-m;
      color: $az-black;
    }
  }

  .titleMx {
    font-family: $font-az-cond-medium;
    font-size: $az-spacing-xs;
    text-align: center;

    @media #{$small-and-down} {
      font-size: $az-spacing-14;
      line-height: $az-spacing-xxs;
      color: $az-black;
      text-align: left;
      padding-right: $az-spacing-5xs;
    }
  }
}

.container {
  margin-top: $az-spacing-xs;
  margin-bottom: $az-spacing-xs;
}

.title {
  margin-bottom: $az-spacing-5;
  font-family: $font-az-cond;
  font-size: $font-size-16;
  color: $az-black;
}

.subTitle {
  font-size: $font-size-14;
  color: $az-grey-6;
  margin-bottom: $az-spacing-xxs;
}

.selectedBreadCrumb {
  font-weight: bold;
  color: $az-black;
}

.choice {
  font-size: $font-size-16;
  color: $az-grey-6;
  margin-bottom: $az-spacing-xs;
  cursor: pointer;
}

.spacing-xs-2 {
  margin: 0 !important; // for overriding Mui styles
}

.addVehicleActionRow {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.addVehicleActionRowButton {
  margin-top: $az-spacing-xxs;
  margin-right: $az-spacing-xxs;
  min-width: $az-spacing-335;
}

.addVehicleMarginRight {
  margin-right: $az-spacing-14;
  height: $az-spacing-18;
}

.continueShoppingRowButton {
  margin-top: $az-spacing-xxs;
  margin-right: $az-spacing-xxs;
  min-width: $az-spacing-335;
}

.vehicleInfo {
  font-family: $font-az-helvetica-medium;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.13;
  max-width: 288px;
}

.breadcrumbSeparator {
  color: $az-grey-5;
  margin: $az-spacing-zero $az-spacing-4xs $az-spacing-zero $az-spacing-4xs;
}

.breadcrumbLinkAZ {
  margin: $az-spacing-5 $az-spacing-5 $az-spacing-5 $az-spacing-zero;

  @media #{$small-and-down} {
    padding: $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-spacing-xxs;
  }
}

.listItem {
  text-transform: capitalize;
  color: $az-grey-6;
  padding: $az-spacing-zero;
  margin-bottom: $az-spacing-5xs;
}

button.buttonStyle {
  color: $az-grey-6;
  padding: $az-spacing-zero;
  background: transparent;
  border: none;
  cursor: pointer;
}

.breadcrumbList {
  padding: $az-spacing-zero;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  white-space: nowrap;
  margin: $az-spacing-xs $az-spacing-zero $az-spacing-xs $az-spacing-zero !important;

  @media #{$medium-only} {
    margin: $az-spacing-18 $az-spacing-zero $az-spacing-xs $az-spacing-zero !important;
  }

  @media #{$small-and-down} {
    margin: $az-spacing-xxs $az-spacing-zero $az-spacing-xxs $az-spacing-zero !important;
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin-top: $az-spacing-xxs;
}
