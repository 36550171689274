@import '../../../src/theme/newVariables.scss';

.sisterStoreStatus {
  font-style: italic;
}

.storePickupAvailability {
  margin-right: $az-spacing-1;
}

.inStockSisterStoreContainer {
  color: $az-green-1;
  display: inline-block;
}

.bold {
  color: $az-black !important;
  font-weight: bold;
}

.toggleListItemContainer {
  margin-bottom: $az-spacing-4xs;
}

.skeletonLoading {
  transform-origin: 50% 50%;
  transform: scale(0.99, 0.9);
}

.toggleListItemContainer:empty,
.toggleListItemContainer:last-child {
  margin: 0;
}

.toggleContainer {
  width: $az-percent-100;
  padding: $az-spacing-5xs;
  background-color: $az-grey-1;

  .segmentedToggleContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: inset $az-spacing-neg-1 $az-spacing-zero $az-spacing-zero $az-spacing-zero
      $az-grey-1;
    background-color: $az-grey-1;
    padding: $az-spacing-5xs;

    .segmentedToggle {
      height: auto;
      padding: $az-spacing-xxs $az-spacing-5xs $az-spacing-xxs $az-spacing-xxs;
      background-color: $az-white;
      border-radius: $az-spacing-5xs;
      box-shadow: $az-box-shadow-gray-6;
    }

    .rootToggleButton {
      background-color: $az-grey-1;

      &:hover {
        box-shadow: $az-box-shadow-gray-4;
        background-color: $az-grey-2;
      }
    }

    .selectedToggleButton {
      background-color: $az-white;
      box-shadow: $az-box-shadow-gray-4;

      &:hover {
        background-color: $az-grey-2;
      }
    }

    .labelToggleButton {
      justify-content: flex-start;
    }
  }

  .inlineToggleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    box-shadow: inset $az-spacing-neg-1 $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-white;
    background-color: $az-grey-1;
    padding-left: $az-spacing-5xs;
    padding-right: $az-spacing-5xs;

    @media #{$small-and-down} {
      flex-direction: column;
      padding-right: $az-spacing-xxxs;
      padding-bottom: $az-spacing-5xs;
    }

    .inlineToggle {
      height: auto;
      width: $az-percent-100;
      padding: $az-spacing-xxs;
      margin: $az-spacing-4xs $az-spacing-5xs;
      cursor: pointer;

      @media #{$small-and-down} {
        margin-bottom: $az-spacing-5xs;
      }

      background-color: $az-white;
      border-radius: $az-spacing-5xs;
      box-shadow: $az-box-shadow-gray-6;

      &:hover {
        background-color: $az-white;
      }

      .labelToggleButton {
        justify-content: flex-start;
      }
    }
  }

  .notAvailable {
    color: $az-color-error;
    font-size: $font-size-12;
    display: inline-flex;
    margin-top: $az-spacing-5xs;
    margin-bottom: $az-spacing-2;
  }

  .notAvailablePreferredStore {
    color: $az-color-error;
    font-size: $font-size-12;
    display: inline-flex;
    margin-bottom: $az-spacing-2;
  }

  .storeLink {
    color: $az-grey-6;
    display: inline-flex;
  }

  .selectStore {
    font-weight: normal;
  }

  .storeDetails {
    color: $az-grey-5;
    text-align: left;
  }

  .sisterStoreDetails {
    color: $az-grey-6;
    text-align: left;
    text-transform: capitalize;
  }

  .deliveryGroupLabel {
    font-style: normal;
    font-weight: 700;
    font-family: $font-az-helvetica;
    font-size: $font-size-14;
    color: $az-grey-5;
    line-height: 1.43;
    margin-bottom: $az-spacing-5xs;
  }

  .listItemContainer {
    margin-top: $az-spacing-5xs;
    letter-spacing: normal;

    .listItemIcon {
      min-width: 32px;
      max-width: 32px;
      margin-right: 4px;
      display: inline-block;
      text-align: right;
      vertical-align: top;

      img {
        height: 16px;
      }
    }

    .listItemDetails {
      line-height: 1.27;
      font-size: 11px;
      display: inline-block;
      width: calc(100% - 36px);
    }

    .listItemHeader {
      font-weight: 500;
      font-size: $font-size-12;
      color: $az-black;
      flex-wrap: wrap;
      line-height: 1.17;
      font-style: normal;

      .nextDayHeader {
        color: $az-blue-6;
        font-style: italic;
      }

      .sameDayHeader {
        color: $az-dark-orange-3;
        font-style: italic;
      }
    }

    .infoPopperIcon {
      width: $az-spacing-xxxs;
      height: $az-spacing-xxxs;
      line-height: $az-spacing-zero;
      margin-left: $az-spacing-2;
      font-weight: normal;
    }
  }
}

.nddPopper {
  z-index: 1601 !important;
}

.shippingOptionDetail {
  display: inline;
  flex: 1 1 auto;
  align-self: stretch;
  font-size: $font-size-12;
  color: $az-black-1;
  flex-wrap: wrap;
}

.shippingOptionDetailV3 {
  display: inline;
  flex: 1 1 auto;
  align-self: stretch;
  font-size: $font-size-14;
  font-weight: 400;
  color: $az-black-1;
  flex-wrap: wrap;
}

.inStock {
  color: $az-color-success;
  font-weight: 400;
  font-family: $font-az-helvetica;
  font-size: $font-size-12;
}

.deliveryMessage,
.deliveryMessageV3 {
  color: $az-color-success;
  font-weight: 700;
  font-family: $font-az-helvetica;
}

.deliveryMessage {
  font-size: $font-size-12;
}

.deliveryMessageV3 {
  font-size: $font-size-14;
}

.divider {
  margin: $az-spacing-zero $az-spacing-4xs;
  color: $az-grey-6;
}

.orderWithIn {
  color: $az-black-1;
}

.fullWidth {
  max-width: 100%;

  /* Firefox specific horizontal scroll fix */
  flex-direction: unset;
}

.searchStoreButton {
  font-weight: 600;
}
