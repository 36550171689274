@import '../../../theme/newVariables';

.freeShippingContainer {
  display: flex;
  justify-content: flex-start;
  gap: var(--st-unit-3);
  align-items: center;

  padding-bottom: var(--st-unit-3);
  font-family: $font-az-helvetica;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  font-style: normal;
  color: $az-black;

  .freeDelivery {
    font-weight: 700;
    font-family: $font-az-helvetica-heavy;
  }
  @media #{$small-and-down} {
    font-size: 14px;
    line-height: 20px;
  }
}

.freeShippingContainerBorder {
  border-bottom: 1px solid $az-grey-16;
}
