@import '../../../theme/newVariables';

.horizontalDivider {
  height: $az-spacing-1;
  background: $az-grey-9;

  @media #{$small-and-down} {
    margin: $az-spacing-zero $az-spacing-xxs;
  }

  @media #{$extra-large-and-up} {
    display: none;
  }
}

.container {
  @media #{$extra-large-and-up} {
    padding-left: $az-spacing-m;
  }

  @media #{$medium-only} {
    padding-top: $az-spacing-l;
  }

  @media #{$small-and-down} {
    padding: $az-spacing-s $az-spacing-xxs $az-spacing-zero $az-spacing-xxs;
  }
}

.title {
  margin-bottom: $az-spacing-m;
}

.description {
  margin-bottom: $az-spacing-4xs;
}
