@import '@/theme/newVariables';

.parentSkuCard {
  padding: 20px 12px;
  display: flex;
  align-items: center;
  border: solid 1px #a9aaa8;
  background-color: #fff;
}

.parentSkuImage {
  flex: 0 0 auto;
  margin: 2px 12px 0 0;
}

.parentSkuDescriptionSection {
  flex: 1 1 auto;
}

.parentSkuDescription {
  font-size: 12px;
  font-weight: bold;
}

.locationSection {
  font-size: 10px;
  color: #484848;
}

.locationLabel {
  font-weight: bold;
  margin-right: 4px;
}

.parentSkuPriceDetails {
  flex: 0 0 auto;
  text-align: right;
}

.parentSkuQuantity {
  margin-bottom: 8px;
  font-size: 12px;
}

.parentSkuQuantityLabel {
  text-transform: uppercase;
  color: #5b5d5b;
  margin-right: 2px;
}

.parentSkuQuantityValue {
  font-weight: bold;
}
