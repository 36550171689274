// TODO: remove when starc media query implemented
@import '@/theme/newVariables';

.relatedProductsContainer {
  margin-top: var(--st-unit-11);
  .relatedProductsHeader {
    background: url(https://www.autozone.com/cdn/images/B2C/US/content/landing-page/crc/rectangle@3x.png)
      no-repeat 0 100% transparent;
    background-size: var(--st-unit-21) var(--st-unit-1);
  }
  .cartButton {
    font-size: var(--st-unit-4);
  }
  .ctjDescription {
    margin-top: var(--st-unit-1);
  }
}

// TODO: Refer to STARC docs when updated how to implement STARC media queries
// @media (--st-viewport-s) {
@media #{$small-and-down} {
  .relatedProductsContainer {
    background: url('/images/dashlights-pattern.png') -341px -85px/1134px auto repeat-y;
    margin-top: var(--st-unit-4);
  }
  .exclamationIcon {
    margin-top: -2px;
  }
}
