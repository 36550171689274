@import '../../../theme/newVariables';

.clearance {
  display: inline-block;
  background-color: $az-grey-2;
  color: $az-black;
  padding: 4px 8px;
  margin: 4px 0;
  font-weight: 700;
  border-radius: 8px;
  text-emphasis: center;
  font-size: 12px;
  line-height: 16px;
  max-height: 28px;

  @media #{$small-and-down} {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 20px;
  }
}
