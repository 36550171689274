@import '../../../../theme/newVariables';

.superSkuOptionSelector {
  gap: var(--st-unit-3);

  .optionName {
    font-size: var(--st-unit-4);
    margin-right: var(--st-unit-2);

    @media #{$small-and-down} {
      font-size: 14px;
    }
  }

  .selectedOption {
    font-size: var(--st-unit-4);

    @media #{$small-and-down} {
      font-size: 14px;
    }
  }

  .optionsContainer {
    position: relative;

    .options {
      overflow: hidden;
      max-width: 100%;
      gap: var(--st-unit-3);
      padding: 2px;

      @media #{$medium-and-down} {
        overflow: auto;
        padding-bottom: var(--st-unit-2);
      }

      .optionButton {
        padding: 9px var(--st-unit-2);
        border: 1px solid var(--st-color-gray-400);
        border-radius: var(--st-unit-1);
        font-size: 14px;

        @media #{$small-and-down} {
          font-size: 12px;
        }

        &:hover:not(.selectedOptionButton) {
          outline: 2px solid var(--st-color-gray-500);
        }
      }

      .selectedOptionButton {
        position: relative;
        padding: var(--st-unit-2) var(--st-unit-4);
        border: 2px solid var(--st-color-orange-500);
        &::before {
          content: url('/images/orange-selected-checkmark.svg');
          position: absolute;
          left: -5px;
          top: 0;
          transform: scale(0.74);
          height: 0;
        }
      }

      .disabledOptionButton {
        background-color: var(--st-color-gray-200);
        border-color: var(--st-color-gray-200);
        color: var(--st-color-gray-600);
      }
    }

    .arrowButton {
      position: absolute;
      width: var(--st-unit-8) !important;
      height: var(--st-unit-8);
      background-color: var(--st-color-gray-100);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--st-unit-1);

      @media #{$medium-only} {
        top: var(--st-unit-1);
      }

      @media #{$small-and-down} {
        display: none;
      }

      &.arrowButtonLeft {
        left: 0;
        rotate: 180deg;
      }

      &.arrowButtonRight {
        right: 0;
      }

      &.hidden {
        display: none;
      }
    }
  }
}
