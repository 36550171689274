@import '@/theme/newVariables';

.pinchZoomContainer {
  .pinchZoomWrapper {
    width: $az-percent-100;
    height: $az-percent-100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pinchZoomContent {
    padding: $az-spacing-zero $az-spacing-25;
  }
}

.overlayTextContainer {
  position: absolute;
  top: $az-percent-50;
  left: $az-percent-50;
  transform: translate(-50%, -50%);
  background-color: rgba(72, 72, 72, 0.65);
  padding: $az-spacing-5 $az-spacing-4xs $az-spacing-4xs $az-spacing-9;
  border-radius: $az-spacing-5xs;
}

.overLayText {
  display: inline-block;
  width: $az-spacing-125;
  height: $az-spacing-xxs;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

.carouselImage {
  height: 100%;
  max-height: 100%;
  max-width: 100%;

  @media #{$small-and-down} {
    height: 100%;
  }
}
