@import '../../../../theme/newVariables.scss';

.discountCaption {
  font-weight: normal;
  font-size: 12px;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: $az-spacing-point-four;
  color: $az-grey-11;
  margin-bottom: 5px;
}

.clearanceCoreWrapper {
  text-align: right;
}

.coreInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media #{$medium-and-up} {
    margin-right: 8px;
  }
}

.marginBtm {
  margin-bottom: 8px;
}

.clearanceWrapper {
  margin-top: $az-spacing-10;
  margin-bottom: 24px;
}

.horizantalStrikeLine {
  background-color: $az-grey-9;
  height: $az-spacing-1;

  @media #{$small-and-down} {
    width: $az-percent-60;
    margin-bottom: $az-spacing-15;
  }
}
