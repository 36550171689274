@import '../../theme/newVariables';

.frequentContainer {
  width: $az-percent-100;
  min-height: $az-spacing-300;
  padding: $az-spacing-l $az-spacing-zero;
  background-color: $az-grey-1;
}

.miniCartPopup {
  @media #{$small-and-down} {
    display: block;
  }
}

.productImage {
  display: block;
  width: $az-spacing-140;
  padding: $az-spacing-15 $az-spacing-zero;
  margin: $az-spacing-zero auto;
}

.innerSpec {
  font-weight: bold;

  @media #{$medium-only} {
    font-size: $font-size-16;
    color: $az-black;
  }

  .warrantyYrStyle {
    @media #{$medium-only} {
      text-decoration: underline;
    }
  }
}

/* product detail specification */

.feature {
  padding: $az-spacing-10 $az-spacing-zero;

  @media #{$extra-large-and-up} {
    text-indent: $az-percent-zero;
  }
}

.featurelist {
  padding: $az-spacing-9 $az-spacing-zero;
  font-size: $font-size-15;

  img {
    padding-right: $az-spacing-10;
  }
}

.specInnerRow {
  padding: $az-spacing-10;
  font-size: $font-size-16;
  line-height: $az-spacing-25;
  font-weight: normal;
  text-transform: capitalize;

  &:nth-child(even) {
    background: $az-grey-1;
  }

  @media #{$extra-large-and-down} {
    color: $az-black;
  }
}

button.warranty {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  padding-left: $az-spacing-zero;
}

.mainProductWrapper {
  @media #{$medium-only} {
    margin: $az-spacing-zero $az-spacing-88;
  }
}

.productContainer {
  @media #{$small-and-down} {
    margin: $az-spacing-zero $az-spacing-xxs;
    padding-bottom: $az-spacing-m;
  }
}

.mainContentContainer {
  padding: $az-spacing-zero $az-spacing-xs;

  @media #{$medium-and-down},
  #{$medium-only} {
    padding: $az-spacing-zero;
  }
}

/* product detail common */
.pdpWrapper {
  .expansionPanelSummary {
    padding: $az-spacing-zero;
    margin: $az-spacing-zero $az-spacing-xxs;
    align-items: center;
    border-bottom-color: $az-grey-2;
  }

  .expansionPanelIcon {
    width: $az-spacing-s;
  }

  .expansionPanelDetails {
    display: block;
  }
}

.miniCartFlyoutDialog {
  flex: 1 1 auto;
}

.customPaperDrawer {
  min-width: $az-spacing-424;
  overflow-x: hidden;
  @media #{$small-and-down} {
    min-width: unset;
    left: 0;
  }
}
