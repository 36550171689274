@import '@/theme/newVariables';

.addToCartButtonSection {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media #{$medium-only} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .addToCartButton {
    padding: 13.5px var(--st-unit-9);
    box-sizing: border-box;

    @media #{$medium-only} {
      margin-left: 8px;
    }

    @media #{$medium-and-up} {
      width: 256px;
    }
  }

  .assortmentTotalText {
    font-size: 14px;
    margin-bottom: var(--st-unit-2);

    @media #{$medium-and-up} {
      margin-bottom: 0;
    }

    @media #{$extra-large-and-up} {
      margin-bottom: 5px;
    }
  }

  .itemsTotalPrice {
    margin: 0 8px 0 4px;
  }
}
