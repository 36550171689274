@import '@/theme/newVariables.scss';

.corePriceWrapper {
  display: flex;
  flex-direction: column;
  width: 322px;

  &:lang(pt) {
    margin-bottom: 8px;
  }

  @media #{$small-and-down} {
    width: 100%;
  }
}
.corePriceRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.corePriceLabel {
  margin-right: 6px;
}

.corePriceRowSeperator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $az-grey-3;
  padding-top: 2px;

  &:lang(pt) {
    padding-top: 4px;
  }
}

.addMargin {
  margin: 8px 0;
}
.pbottom {
  padding-bottom: 4px;

  &:lang(pt) {
    padding-bottom: 6px;
  }
}

.coreChargeWrapper {
  display: flex;
  flex-direction: column;
  color: #949494;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}

.priceStyle {
  font-size: $font-size-12;
  line-height: 31px;
}

.breakdownTitle {
  font-weight: bold;
  font-size: $font-size-14;
  line-height: 22px;
  color: $az-black;
}

.priceSkeleton {
  transform: none;
}

.tooltipLabelWrapper {
  margin-right: var(--st-unit-1);
}

.infoTooltip {
  width: var(--st-unit-3);
  height: var(--st-unit-3);

  img {
    display: inline-block;
  }
}

.refundablePriceSkeleton {
  transform: translateY(10px);
  transform-origin: 50% 50%;
}
