@import '../../../theme/newVariables';

.container {
  padding: $az-spacing-zero $az-spacing-m $az-spacing-zero $az-spacing-xxs;
}

.title {
  margin-bottom: $az-spacing-m;
}

.descriptionContainer {
  display: flex;
  flex-direction: row;

  @media #{$medium-only} {
    padding-left: $az-spacing-xxs;
  }

  @media #{$small-and-down} {
    flex-direction: column;
    margin-bottom: $az-spacing-xxs;

    &:last-child {
      margin-bottom: $az-spacing-m;
    }
  }
}

.description {
  margin-bottom: $az-spacing-m;

  &:first-child {
    margin-right: $az-percent-5;
  }

  @media #{$small-and-down} {
    margin-bottom: $az-spacing-zero;
    margin-top: $az-spacing-4xs;
  }
}
