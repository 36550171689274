@import '@/theme/newVariables';

.underline::after {
  content: '';
  border-bottom: $az-spacing-5xs solid $az-medium-orange;
  margin: $az-spacing-4xs $az-spacing-zero;
  display: block;
  width: $az-spacing-84;
  transform: skewX(-30deg);
}

.nextUnderline::after {
  content: '\A';
  white-space: pre-wrap;
  border-bottom: $az-spacing-5xs solid $az-medium-orange;
  margin: $az-spacing-4xs $az-spacing-zero;
  display: block;
  position: relative;
  width: $az-spacing-84;
  transform: skewX(-30deg);
}

.center {
  text-align: center;

  &::after {
    margin: $az-spacing-4xs auto;
  }
}

.inlineheader {
  display: flex;
}

.centerInline {
  padding-left: 33.3%;
}

.inlineH2orH3 {
  margin-left: $font-size-half;
}

.firstText {
  display: inline-block;
}
