@import '../../../theme/newVariables';

.breadcrumb-wrapper {
  margin: $az-spacing-xs $az-spacing-zero;
  display: flex;

  @media #{$small-and-down} {
    margin: $az-spacing-xxs;
  }

  @media #{$medium-only} {
    padding: $az-spacing-zero $az-spacing-xxs;
  }
}

// All styles below can be removed when the STARC Breadcrumb is import from STARC

.breadcrumb {
  white-space: nowrap;
  padding-bottom: $az-spacing-3;
  overflow-x: auto;

  @media #{$extra-large-and-up} {
    white-space: normal;
    overflow-x: visible;
  }
}

.breadcrumb-item {
  font-weight: 400;
  line-height: $az-spacing-xs;
  color: $az-grey-14;
  white-space: nowrap;

  @media #{$extra-large-and-up} {
    white-space: normal;
    overflow-x: visible;
  }
}

.breadcrumb-link {
  font-size: $font-size-12;
  font-weight: 600;
  text-decoration: none !important;
  color: $az-grey-14 !important;
  position: relative;

  &:lang(pt) {
    font-size: $font-size-16;
    font-weight: 700;
  }

  &:lang(pt):after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    background-color: $az-grey-14;
  }
}

.breadcrumb-link:hover {
  color: #202124 !important;

  &::after {
    background-color: #202124 !important;
  }
}

.breadcrumbDisabled {
  color: #202124 !important;

  &:lang(pt):after {
    content: none;
  }
}

.breadcrumb-separator {
  font-weight: 400;
  line-height: $az-spacing-xs;
  margin-left: $az-spacing-4xs;
  margin-right: $az-spacing-4xs;

  &:lang(pt) {
    font-size: 0;
    display: inline-block;
    width: 30px;
    position: relative;
  }

  &:lang(pt):after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 1px;
    height: 30px;
    bottom: 0px;
    transform: rotate(30deg);
    left: 15px;
    background-color: $az-grey-14;
  }
}
