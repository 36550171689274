@import '../../theme/newVariables.scss';

.fulfilmentGroup {
  display: flex;
  flex-direction: column;

  .fulfillmentOptionCardsContainer {
    display: flex;
    flex-wrap: row-wrap;
    justify-content: flex-start;
    gap: 8px;

    .subgroup {
      display: flex;
      flex-direction: column;
      flex-wrap: row-wrap;
    }

    .bopusCard {
      display: flex;
      flex-direction: column;
      padding: 0px;
      border-radius: 4px;
      font-family: $font-az-helvetica;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 2px;
      color: #202124;

      @media #{$medium-and-down} {
        padding-left: 4px;
        padding-right: 4px;
      }
    }

    .sthCard {
      font-family: $font-az-helvetica;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 2px;
      color: #202124;
      word-wrap: break-word;
      padding: 0px;

      @media #{$medium-and-down} {
        padding-left: 4px;
        padding-right: 4px;
      }
    }

    .buttonSubtextStyles {
      display: flex;
      flex-direction: row;
      font-family: $font-az-helvetica;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #202124;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0 2px;

      @media #{$large-and-up} {
        gap: 0 4px;
      }
    }
    .selectStoreStyles {
      color: #202124;
      font-family: $font-az-helvetica;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
    }
    .estDeliveryTextContainerStyles {
      flex-direction: column;

      @media #{$medium-and-up} {
        flex-direction: row;
      }
    }

    .homeDeliveryUnavailable,
    .homeDeliveryAvailable {
      display: flex;
      flex-direction: column;
    }

    .bopusAvailableTextContainer {
      display: flex;
      flex-direction: column;

      .readyToday {
        font-family: $font-az-helvetica;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #202124;
        display: flex;
        gap: 0 2px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    .successText {
      color: $az-color-success;
      font-weight: 700;
    }

    .deliveryTodayOrTomorrow {
      text-transform: lowercase;
      color: $az-color-success;
      font-weight: 700;
      font-family: $font-az-helvetica;
      font-size: $font-size-10;

      @media #{$medium-and-down} {
        margin-left: $az-spacing-2;
        font-size: 10px;
      }

      .deliveryMessageCapitalized {
        text-transform: capitalize;
      }
    }

    .storeTimeMargin {
      text-transform: lowercase;
      margin-left: 2px;
    }

    .freeText,
    .freeTextUppercase {
      font-family: $font-az-helvetica;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;
      color: #202124;
    }

    .nddFree {
      font-family: $font-az-helvetica;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: #202124;
    }

    .threeSplit {
      line-height: 14px;
    }

    .freeTextUppercase {
      text-transform: uppercase;
    }

    .activeButtonStyles {
      position: relative;
      border: 2px solid #f26d0d !important;

      &::before {
        content: url('/images/orange-selected-checkmark.svg');
        position: absolute;
        left: $az-spacing-neg-3;
        top: $az-spacing-zero;
        transform: scale(0.9);
        height: 0px;
      }
    }

    .disabledText {
      color: #949494 !important;
    }

    .disabledStyles {
      border: transparent !important;
      background: #f2f2f2 !important;
      border-radius: 4px;
      cursor: default;
      font-family: $font-az-helvetica;
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
    }

    .buttonCommonStyles {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex: 1 1 0;
      flex-wrap: wrap;
      background: #fff;
      border: 2px solid #949494;
      border-radius: 4px;
      word-break: break-word;
      gap: 0 2px;
      padding: 8px;
    }

    .v2ButtonStyles {
      justify-content: center;
      gap: 0;
      min-height: $az-spacing-75;
    }

    .v2ButtonShadow {
      &:hover {
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
          0px 1px 18px 0px rgba(0, 0, 0, 0.12);
      }
    }

    .cartButtonPadding {
      padding: 8px;
    }

    .hiddenAriaLiveTag {
      position: absolute;
      left: -999999px;
      height: $az-spacing-1;
      width: $az-spacing-1;
      overflow: hidden;
    }
  }

  .applyMargins {
    margin-left: 14px;
    margin-top: 16px;
  }

  .subgroupContainer {
    height: auto;
    width: $az-percent-100;
    margin: 16px 0px 0px;
    gap: 16px;

    .searchStoreButton {
      margin-top: unset;
    }

    .subTextBold {
      color: #484848;
      font-family: $font-az-helvetica;
      font-style: normal;
      font-weight: 700 !important;
      font-size: 12px;

      @media #{$small-and-down} {
        line-height: 16px;
      }
    }

    .subtext {
      font-family: $font-az-helvetica;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #484848;

      @media #{$small-and-down} {
        line-height: 16px;
      }
    }

    .shippingOptionDetailV3 {
      font-size: $font-size-12;
      font-weight: 400;
      color: $az-grey-6;
      flex-wrap: wrap;
      line-height: $az-spacing-xs;
    }

    .cartAdditionalMessageText {
      color: $az-grey-14;
      font-style: italic;
      left: 28px;
    }

    .sddOrderByLabel {
      margin-top: 4px;
    }

    .nddOrderByLabel {
      margin-top: 4px;
      width: calc(100% - 36px);
    }

    .deliveryMessage {
      text-transform: lowercase;
      color: $az-color-success;
      font-weight: 700;
      font-family: $font-az-helvetica;
      font-size: $font-size-12;
    }

    .successBoldedMessage {
      color: $az-color-success;
      font-weight: 700;
    }

    .deliveryMessageCapitalized {
      text-transform: capitalize;
    }

    .estDeliveryText {
      font-family: $font-az-helvetica;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #484848;

      @media #{$small-and-down} {
        line-height: 16px;
      }
    }

    .listItemContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: $az-spacing-xs;
      &.unsetHeight {
        height: unset;
      }
      gap: 10px;
    }

    .listItemDetails {
      line-height: 16px;
      font-size: $font-size-12;
      display: inline-block;
      width: calc(100% - 36px);
    }

    .listItemHeader {
      font-weight: bold;
      font-size: $font-size-12;
      color: $az-black;
      flex-wrap: wrap;
      line-height: 20px;
      font-style: normal;

      .nextDayHeader {
        font-weight: 500;
        color: $az-blue-6;
        font-style: italic;
      }

      .sameDayHeader {
        font-weight: 500;
        color: $az-dark-orange-3;
        font-style: italic;
      }
    }

    .inStockLabel {
      font-family: $font-az-helvetica;
      color: #484848;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;

      @media #{$small-and-down} {
        line-height: 16px;
        letter-spacing: normal;
      }
    }

    .tickmarkContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: flex-start;
      width: 24px;
      height: 24px;
      padding: 4px;
    }

    .inStockLabelContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      line-height: $az-spacing-xs;
    }

    .NextDaylistItemContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      height: auto;
      &.unsetHeight {
        height: unset;
        align-items: start;
      }
    }

    .storePickUpContainer {
      display: flex;
      align-items: center;
      gap: 5px;
      white-space: pre;
      height: $az-spacing-xs;
      line-height: $az-spacing-xs;
    }

    .divider {
      margin: $az-spacing-zero $az-spacing-4xs;
      color: $az-grey-6;
    }

    .notAvailable {
      gap: 0 3px;
      color: $az-color-error;
      font-family: $font-az-helvetica;
      font-size: $font-size-12;
      display: flex;
      align-items: center;
      margin-top: $az-spacing-5xs;
      margin-bottom: $az-spacing-2;
    }

    .notAvailableIconContainer {
      display: flex;
      align-items: flex-end;
      align-self: flex-start;
      padding: 4px;
    }

    .selectStoreBtn {
      font-weight: bold;
    }

    .storePickupAvailability {
      display: flex;
      color: $az-grey-6;
      align-items: center;
      margin-right: $az-spacing-1;
      font-size: $font-size-12;
    }

    .storeLink {
      color: $az-grey-6;
      display: inline-flex;
    }

    .notAvailablePreferredStore {
      color: $az-color-error;
      font-size: $font-size-12;
      font-weight: 700;
      display: inline-flex;
    }

    .storeDetails {
      color: $az-grey-6;
      text-align: left;
      font-weight: bold;
    }

    .sisterStoreDetails {
      color: $az-grey-6;
      text-align: left;
      font-weight: bold;
      text-transform: capitalize;
    }

    .lowercase {
      text-transform: lowercase;
    }

    .inStock {
      color: $az-color-success;
      font-weight: 700;
      font-family: $font-az-helvetica;
      font-size: $font-size-12;
    }

    .inStockSisterStoreContainer {
      color: $az-grey-6;
      display: inline-block;

      .greenText {
        font-weight: 700;
        color: $az-color-success;
      }
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

.skeletonLoading {
  transform: scale(1, 0.9);
  transform-origin: 50% 50%;
}

.desktopBoldText {
  font-weight: 500;
}
