@import '@/theme/newVariables';

.constraintItemCard {
  padding: 20px 12px;
  display: flex;
  align-items: center;
  border: solid 1px #a9aaa8;
  background-color: #fff;
}

.selected {
  border: solid 2px #f26d0d;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 12px;
    border-left-color: #f26d0d;
    border-top-color: #f26d0d;
  }

  .selectedIcon {
    position: absolute;
    top: -8px;
    left: -1px;
    width: 15px;
    fill: white;

    @media #{$small-and-down} {
      top: -4px;
    }
  }
}

.constraintItemImage {
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.constraintItemDescriptionSection {
  flex: 1 1 auto;
}

.constraintItemDescription {
  font-size: 12px;
  font-weight: bold;
  margin-right: 16px;
}

.locationSection {
  font-size: 10px;
  color: #484848;
}

.locationLabel {
  font-weight: bold;
  margin-right: 4px;
}

.constraintItemPriceDetails {
  flex: 0 0 auto;
  text-align: right;
  line-height: 14px;
}

.constraintItemQuantity {
  margin-bottom: 8px;
  font-size: 12px;
  text-align: center;
}

.constraintItemPrice {
  > * {
    align-items: center;
  }
}

.constraintItemQuantityLabel {
  text-transform: uppercase;
  color: #5b5d5b;
  margin-right: 2px;
}

.constraintItemQuantityValue {
  font-weight: bold;
}
