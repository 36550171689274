@import '../../../theme/newVariables';

.prodDetailSpacing {
  margin-top: $az-spacing-xxs;
}

.addToCartButton {
  padding: 13.5px;
  border: 1px solid #202124;
  span {
    line-height: var(--st-unit-6);
  }
}

.addToCartButtonDisabled {
  border: none;

  span {
    color: #949494;
  }
}

.imgDealsLink {
  padding-right: $az-spacing-4xs;
}

.specialDealsLink {
  font-weight: 500;
  text-align: $rdirection;
  color: $az-black;
}

.dealsButtonStyle {
  text-align: $cdirection;
}

.location {
  margin-top: $az-spacing-xxs;
  margin-bottom: $az-spacing-4xs;
}

.noteMore {
  text-align: $ldirection;
  margin-top: $az-spacing-xxs;
}

.applicationText {
  color: $az-grey-6;
}

.coreInfoheader {
  text-align: $ldirection;
  text-transform: uppercase;
}

.warranty {
  padding: $az-spacing-5 $az-spacing-zero $az-spacing-zero;
}

.warrantyButton {
  margin-left: $az-spacing-5;
  padding: $az-spacing-zero;
  font-size: $az-spacing-14;
}

.warrantyText {
  padding-left: $az-spacing-6;
  @media #{$small-and-down} {
    font-size: $font-size-12;
  }
}

.pagination {
  text-align: $cdirection;
}

.unitPrice {
  text-align: $cdirection;
  text-transform: capitalize;
  color: $az-grey-5;
  margin-top: $az-spacing-4xs;
}

.inlineTypography {
  display: inline-block;
}

.pipeSymbol {
  border-right: $az-spacing-1 solid $az-grey-3;
  display: flex;
}

.pipeSymbolLeft {
  border-left: $az-spacing-1 solid $az-grey-3;
  padding-left: $az-spacing-15;
  margin-left: $az-spacing-15;
}

.horizantalLine {
  border-top: $az-spacing-zero;
  margin: $az-spacing-xs $az-spacing-zero $az-spacing-xxs $az-spacing-zero;
  height: 1px;
  background-color: $az-grey-9;

  &.noPricing {
    background-color: transparent;
  }
}

.mobileSpace {
  @media #{$small-and-down} {
    margin-top: $az-spacing-xs;
  }
}

.corePrice {
  .quantityContainer {
    margin-top: $az-spacing-xxxs;
  }

  .tabletSpace {
    margin: $az-spacing-zero $az-spacing-4xl;
    padding: $az-spacing-zero $az-spacing-4xs;
  }

  .warrantyLocation {
    margin-top: $az-spacing-xxs;
  }

  .horizantalLine {
    margin: $az-spacing-m $az-spacing-zero $az-spacing-xxs $az-spacing-zero;
  }
}

@media #{$small-and-down} {
  .warranty {
    text-align: $ldirection;
  }

  .unitPrice {
    text-align: $ldirection;
    margin-left: $az-spacing-l;
  }

  .quantityContainer {
    margin-bottom: $az-spacing-xxs;
    margin-top: $az-spacing-xs;

    @media #{$small-and-down} {
      margin-bottom: $az-spacing-xs;
    }
  }

  .pipeSymbolLeft {
    padding-left: $az-spacing-7;
    margin-left: $az-spacing-7;
  }
}

.discountCaption {
  font-weight: normal;
  font-size: 12px;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: $az-spacing-point-four;
  color: $az-grey-11;
  margin-bottom: 5px;
}

.priceCaption {
  height: $az-spacing-m;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  color: $az-grey-5;
  margin-top: 5px;
}

.priceCaptionHidden {
  margin-top: $az-spacing-37;
}

.reviewWrapper {
  display: inline-flex;
  margin-bottom: $az-spacing-5;
  align-items: center;
}

.productMetaWrapper {
  display: flex;
  align-items: $cdirection;
  .productMetaLabel {
    font-size: $font-size-14;

    @media #{$small-and-down} {
      font-size: $font-size-12;
    }
  }
}

.oemInline {
  display: flex;
  flex-direction: row;
  column-gap: $az-spacing-xxxs;
  row-gap: $az-spacing-4xs;
  flex-wrap: wrap;
}

.priceQuantityWrapper {
  display: flex;
  justify-content: space-between;
  width: $az-percent-100;
}

.quantityMargin {
  margin-bottom: $az-spacing-xxs;

  @media #{$small-and-down} {
    margin-bottom: $az-spacing-xs;
  }
}

.dealsBadgeContainer {
  margin-bottom: $az-spacing-xs;

  .dealsDrawerButton {
    margin-bottom: $az-spacing-xxs;

    @media #{$small-and-down} {
      margin-bottom: $az-spacing-xxxs;
    }
  }
}

.paypalDealsBadgeContainer {
  margin-bottom: 0;

  .dealsDrawerButton {
    margin-bottom: $az-spacing-xxs;

    @media #{$small-and-down} {
      margin-bottom: $az-spacing-xxxs;
    }
  }
}

.paypalFulfillmentSpacing {
  @media #{$small-and-down} {
    margin-top: 20px;
  }
}

.warrantyBadge {
  width: $az-spacing-22;
  height: $az-spacing-25;
  margin-right: 6px;
}

.label {
  color: $az-grey-7;
  margin-top: $az-spacing-2;
}

.priceRow {
  display: flex;
  height: 16px;
  margin-bottom: 6px;
  justify-content: space-between;
}

.breakdownTitle {
  font-weight: bold;
  font-size: $font-size-14;
  line-height: 28px;
}

.priceStyle {
  font-size: $font-size-12;
  line-height: 31px;
}

.coreChargeTitle {
  font-size: $font-size-12;
  line-height: 28px;
  color: $az-grey-11;
  width: 260px;
}

.coreChargeStyle {
  font-size: $font-size-12;
  line-height: 36px;
  margin-left: 11px;
  color: $az-grey-11;
  width: 51px;
  text-align: right;
}

.coreChargePlusCoreStyle {
  font-size: $font-size-12;
  line-height: 28px;
  margin-left: 11px;
  color: $az-grey-11;
  width: 51px;
  text-align: right;
}

.tooltipLabelWrapper {
  margin-right: $az-spacing-3;
}

.infoTooltip {
  width: $az-spacing-xxxs;
  height: $az-spacing-xxxs;
}

.separator {
  margin-top: 16px;
  margin-bottom: 2px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid $az-grey-3;
}

.clearanceCoreWrapper {
  text-align: right;
}

.clearanceWrapper {
  margin-top: $az-spacing-10;
}

.fullWidthinMobile {
  @media #{$small-and-down} {
    width: 100%;
  }

  /* Firefox specific horizontal scroll fix */
  flex-direction: unset;
}

.priceSkeleton {
  transform: none;
}

.refundablePriceSkeleton {
  transform: translateY(10px);
  transform-origin: 50% 50%;
}

.container {
  @media #{$medium-only} {
    display: flex;
    justify-content: center;
  }
}

.wrapper {
  @media #{$medium-only} {
    max-width: 560px;
  }
}

.paypalPayLaterPDP {
  margin-bottom: 8px;
}

.rewardsBadge {
  padding-bottom: $az-spacing-4xs;
}

.superSkuOptionsContainer {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  margin: var(--st-unit-4) 0;
  padding: var(--st-unit-4) 0;
  gap: var(--st-unit-4);
}
