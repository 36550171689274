@import '@/theme/newVariables';

.constraintItem {
  margin: 16px 0;
  border-bottom: 1px solid #e5e5e5;

  &:last-child {
    border-bottom: none;
  }
}

.constraintItemWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.constraintItemHeader {
  display: flex;
  align-items: center;

  .constraintItemIcon {
    flex: 0 0 auto;
    width: 20px;
    margin-right: 8px;
    fill: #157400;
  }
}

.constraintItemCardSection {
  margin-top: 16px;
}

.constraintItemTitle {
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: bold;
}

.vehicleFitSection {
  padding: 8px 12px;
}

.vehicleFitSectionHeader {
  display: flex;
  align-items: center;
}

.vehicleDoesNotFitIcon {
  flex: 0 0 auto;
  width: 20px;
  fill: #b30000;
  margin-right: 4px;
}

.vehicleDoesNotFitTitle {
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: bold;
}

.vehicleDoesNotFitDescription {
  font-size: 12px;
  color: #5b5d5b;
}
