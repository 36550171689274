@use '@/theme/newVariables' as *;

.oosAndDoesNotFitCertonaContainer {
  background-color: $az-grey-1;
  padding: 12px 20px;
  margin: 0px -20px 32px -20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 148px;

  @media #{$large-and-up} {
    gap: 0px;
    padding: 0px 0px 0px 6px;
    margin: 0px 0px 20px 0px;
  }

  @media #{$medium-only} {
    background-color: $az-grey-1;
    padding: 0px 0px 10px 6px;
    gap: 2.5px;
    ul {
      padding: 1.5px 0px 1.5px 14px !important;
    }
  }

  @media #{$small-and-down} {
    background-color: $az-grey-1;
    padding: 8px 0px 8px 16px;
    height: 160px;
    ul {
      overflow: hidden !important;
      padding: 1.5px 0px !important;
    }
  }
}

.twoTextsContainer {
  @media #{$small-and-down} {
    display: flex;
    flex-direction: row;
  }
}

.pdpOosText {
  font-size: 18px;
  line-height: 28px;
  flex: 1;
  max-width: 1560px;
  padding-right: 32px;

  @media #{$small-and-down} {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 24px;
    min-height: 50px;
  }
}

.pdpOosBoldText {
  font-weight: 700;
}

.shopAllMobileSpace {
  @media #{$small-and-down} {
    max-width: 259px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    visibility: visible;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.titlesContainer {
  position: relative;
}

.shopAllStyles {
  display: grid;
  font-size: 16px;
  font-weight: 700;
  position: absolute;
  width: 80px;
  height: 45px;
  align-content: end;
  justify-content: center;
  top: -10px;

  &.threeOrMoreItemsAvailable {
    @media #{$large-and-up} {
      left: 980px;
    }

    @media #{$medium-only} {
      right: 7px;
    }
  }

  &.twoItemsAvailable {
    @media #{$large-and-up} {
      left: 635px;
    }

    @media #{$medium-only} {
      right: 7px;
    }
  }

  @media #{$small-and-down} {
    align-content: center;
    top: 0px;
    right: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

.horizontalSkeletonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  background-color: $az-grey-1;
  height: 148px;
  padding-left: 20px;
  margin-bottom: 20px;

  > div > div > div > div > div {
    height: 89px;
  }

  @media #{$medium-only} {
    padding-left: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: unset;
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }

  @media #{$small-and-down} {
    margin: 0px -16px !important;
    height: 160px;
    margin: 0px -20px;
    padding: 8px 0px 8px 16px;
    > div {
      height: 50px;
      + div {
        height: 100px;
      }
    }
  }
}

.horizontalSkeleton {
  height: 92px;
  display: flex;
  overflow: hidden;

  @media #{$small-and-down} {
    height: 90px;
  }
}

.buttonSkeleton {
  background-color: $az-grey-16;
}

.sharedOOSWrapper {
  height: 148px;
  display: flex;
  flex-direction: column;

  @media #{$small-and-down} {
    height: 160px;
  }
}

.doesNotFitNotificationContainer {
  box-shadow: 0 -4px 0 var(--st-color-error);
  background-color: var(--st-color-gray-100);
}

.starcSmallBodyOverride {
  font-size: 14px;
  line-height: 22px;

  @media #{$small-and-down} {
    font-size: 12px;
  }
}

.starcLargeBodyOverride {
  font-size: 18px;
  line-height: 26px;

  @media #{$small-and-down} {
    font-size: 14px;
    line-height: 22px;
  }
}

.removeHorizontalPadding {
  margin-bottom: var(--st-unit-5);

  @media #{$medium-only} {
    margin: 0 -20px var(--st-unit-5);
  }

  @media #{$small-and-down} {
    margin-bottom: var(--st-unit-8);
  }
}
