@import '@/theme/newVariables';

.pdpThumbnailContainer {
  width: $az-spacing-86;
}

.enlargedMediaContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

.videoContainerInline {
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoContainer {
  width: 540px;
  height: 540px;

  @media #{$medium-only} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 440px;
    height: 440px;
  }
}

.enlargedImgBox {
  margin: 0 auto;
  cursor: pointer;
  text-align: $cdirection;
  width: $az-spacing-500;
  height: $az-spacing-500;

  @media #{$medium-only} {
    width: $az-spacing-400;
    height: $az-spacing-400;

    &:focus {
      outline: 0 !important;
    }
  }

  img {
    margin: auto;

    &:hover {
      opacity: 0.5;
      cursor: pointer;
      cursor: zoom-in;
    }
  }
}

.imgHeight {
  height: $az-percent-100;
  position: relative;
  text-align: center;

  &.threeSixtyContainer {
    padding: $az-spacing-zero $az-spacing-25;

    .threeSixtyCarouselImage {
      width: $az-percent-100;
      height: auto;
      max-height: $az-percent-100;
      max-width: $az-percent-100;
    }
  }
}

.productCarouselContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media #{$medium-only} {
    min-height: $az-spacing-416;
  }
}

.enlargedIframe {
  width: $az-percent-100;
  max-width: $az-spacing-500;
  align-self: center;
  margin: 0 auto;

  @media #{$medium-only} {
    width: auto;
    max-width: $az-spacing-400;
    height: $az-spacing-225;
  }
}

.noProdMsg {
  text-align: $cdirection;
}

.desktopCarousel {
  display: flex;
  .desktopMedia {
    width: 100%;
    display: flex;
    flex-direction: column;

    .configurableSkuBadgeContainer {
      padding-left: var(--st-unit-2);
      margin-bottom: var(--st-unit-4);
    }
  }
}

@media #{$small-and-down} {
  .desktopCarousel {
    display: none;
  }
}

@media #{$medium-and-up} {
  .mobileCarousel {
    display: none;
  }

  .threesixtyCaption {
    padding-bottom: $az-spacing-xs;
  }
}

.customCarouselContainer {
  @media #{$small-and-down} {
    min-height: $az-spacing-336;

    .carouselSlidesContainer {
      & > div {
        height: unset;
      }
    }
  }
}
