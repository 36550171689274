@import '@/theme/newVariables';

.bundleDealOptionList {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bundleDealListScroller {
  height: 100%;
  overflow: auto;
}

.bundleDealOptionListTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
}

.bundleDealOptionListDescription {
  font-size: 12px;
  color: #5b5d5b;
}

.separator {
  height: 1px;
  background-color: #e5e5e5;
  margin-top: 8px;
  margin-bottom: 16px;
}

.parentSkuSection {
  background-color: #f2f2f2;
  padding: 16px 16px 20px;
}

.parentSkuSectionTitle {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.bundleDealOptionListMainBody {
  padding: 16px;
}
