@import '@/theme/newVariables.scss';

.priceSkeleton {
  transform: none;
}

.strikethroughPriceWrapper {
  display: flex;
  align-items: flex-end;

  .marginLeft {
    margin-left: 8px;
  }
}
