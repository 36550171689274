@import '@/theme/newVariables';

.titleContainer {
  @media #{$extra-large-and-up} {
    max-width: calc(var(--st-unit-1) * 50) !important;
    min-width: calc(var(--st-unit-1) * 50) !important;
    justify-content: center;
  }
}

.title {
  @media #{$extra-large-and-up} {
    letter-spacing: 0.3px !important;
  }
}

.text {
  font-size: 13.5px;
  line-height: 22px;
  strong {
    font-size: 14px;
    line-height: 22px;
  }
}

.ymmePadding {
  @media #{$large-and-up} {
    padding: 16px 0px;
  }

  @media #{$medium-only} {
    padding: 4px 0px;
  }
}
