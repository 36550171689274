@import '@/theme/newVariables';

.button {
  background-color: transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  @media #{$small-and-down} {
    margin: -10px 0;
  }
  border: 0px transparent;
  padding: 0px;
  // tooltip
  position: relative;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: $az-grey-1;
    }
  }

  &.clicked {
    background-color: $az-grey-16;
  }
}

.popperStyles {
  width: 120px !important;
  height: 38px !important;
  font-size: 14px !important;
  line-height: 22px !important;
  font-weight: 700 !important;
  box-sizing: border-box !important;
}

.paperStyles {
  width: 120px;
  height: 38px;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  color: $az-grey-14;
  border-radius: unset;
}

.popOver {
  margin-top: 8px;
  font-size: 14px;
  width: 100px;
  justify-content: center;
}

.container {
  line-height: 1;
}

.shareIcon {
  width: 100%;
  height: 100%;
}
