@import '@/theme/newVariables';

.rewardsBadge {
  background-color: #d52b1e;
  width: fit-content;
  height: 24px;
  padding: 0 14px 0 8px;
  clip-path: polygon(0 0, 100% 0, 93% 100%, 0 100%);
}

.rewardText {
  font-family: $font-az-helvetica;
  line-height: 17px;
  font-weight: 700;
  color: $az-white;
  font-size: $font-size-12;
  padding: 0 $az-spacing-2;
}

.infoIcon {
  padding: 1px 2px;
  height: 16px !important;
  width: 16px !important;
}

.infoIcon > img {
  width: 12px;
  height: 12px;
  fill: $az-white;
}

.infoToolTipContainer {
  display: flex;
  align-items: center;
}
