@import '@/theme/newVariables';

.relatedPartsPictureList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: var(--st-unit-4);

  @media #{$extra-large-and-up} {
    margin-right: var(--st-unit-8);
  }

  .relatedPartPictureWithPlusSign {
    display: flex;
    align-items: center;

    .relatedPartPlusSign {
      display: flex;
      margin: 0 var(--st-unit-1);
      user-select: none;

      @media #{$medium-and-up} {
        margin: 0 var(--st-unit-4);
      }

      @media #{$large-and-up} {
        margin: 0 var(--st-unit-5);
      }
    }

    .relatedPartDeselected {
      opacity: 0.3;
    }
  }

  .relatedProductImage {
    width: 52px;
    height: 52px;
    display: block;

    @media #{$medium-and-up} {
      width: 57px;
      height: 57px;
    }
  }
}
