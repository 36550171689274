// TODO
@import '../../theme/newVariables.scss';

.root {
  min-width: $az-spacing-xxs;

  &:hover {
    color: $az-medium-orange;
  }
}

.rootCurrent {
  margin-left: $az-spacing-xs;
  margin-right: $az-spacing-xs;
  padding-left: $az-spacing-4xs;
  padding-right: $az-spacing-4xs;
  padding-bottom: $az-spacing-5xs;
  border-bottom: 4px solid $az-medium-orange;
  font-size: $font-size-18;
  line-height: $az-spacing-s;

  @media #{$small-and-down} {
    margin-left: $az-spacing-5xs;
    margin-right: $az-spacing-5xs;
  }
}

.rootEllipsis {
  padding-left: $az-spacing-5xs;
  padding-right: $az-spacing-5xs;
  padding-bottom: $az-spacing-5xs;
  border-bottom: 4px solid transparent;
  font-size: $font-size-18;
  line-height: $az-spacing-s;
}

.rootEnd {
  padding-left: $az-spacing-xxxs;
  padding-right: $az-spacing-xxxs;
  padding-bottom: $az-spacing-5xs;
  border-bottom: 4px solid transparent;
  font-size: $font-size-18;
  line-height: $az-spacing-s;
}

.rootStandard {
  margin-left: $az-spacing-xxs;
  margin-right: $az-spacing-xxs;
  padding-bottom: $az-spacing-5xs;
  border-bottom: 4px solid transparent;
  color: $az-grey-14;
  font-size: $font-size-18;
  line-height: $az-spacing-s;

  @media #{$small-and-down} {
    margin-left: $az-spacing-5xs;
    margin-right: $az-spacing-5xs;
  }
}

.sizeSmall {
  min-width: $az-spacing-4xs;
}

.sizeSmallCurrent {
  padding-left: $az-spacing-4xs;
  padding-right: $az-spacing-4xs;
}

.sizeSmallEllipsis {
  padding-left: $az-spacing-2;
  padding-right: $az-spacing-2;
}

.sizeSmallEnd {
  padding-left: $az-spacing-4xs;
  padding-right: $az-spacing-4xs;
}

.sizeSmallStandard {
  padding-left: $az-spacing-4xs;
  padding-right: $az-spacing-4xs;
}

.sizeLarge {
  min-width: $az-spacing-s;
}

.sizeLargeCurrent {
  padding-left: $az-spacing-xxs;
  padding-right: $az-spacing-xxs;
}

.sizeLargeEllipsis {
  padding-left: $az-spacing-6;
  padding-right: $az-spacing-6;
}

.sizeLargeEnd {
  padding-left: $az-spacing-xxs;
  padding-right: $az-spacing-xxs;
}

.sizeLargeStandard {
  padding-left: $az-spacing-xxs;
  padding-right: $az-spacing-xxs;
}
