@import '@/theme/newVariables';

.fulfillmentSelectionDetails {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fulfillmentSelectionScroller {
  height: 100%;
  padding: 16px;
  overflow: auto;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.fulfillmentSelectionTitle {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 4px;
}

.noDealMessage {
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-12;
  line-height: $az-spacing-18;
  color: $az-black;
  margin: $az-spacing-xxs $az-spacing-zero;
}

.fulfillmentSelectionDescription {
  font-size: 12px;
  text-align: left;
  color: #5b5d5b;
}

.separator {
  height: 1px;
  background-color: #e5e5e5;
  margin-top: 8px;
  margin-bottom: 16px;
}

.bundleDetailsBody {
  padding: 16px;
  flex: 1 1 auto;
}

.errorContainer {
  margin-top: 12px;
  text-align: left;
}

.fulfillmentSelectionFooter {
  padding: 12px;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  width: 100%;

  button {
    width: 100%;
    text-transform: uppercase;

    &:focus {
      background-color: #202124;
    }
  }
}
