@import '../../../theme/newVariables.scss';

.pagination {
  width: $az-percent-100;
  text-align: center;
  margin: $az-spacing-l $az-spacing-zero;
  min-height: 32px;

  @media #{$extra-large-and-up} {
    margin: $az-spacing-xxxl $az-spacing-zero;
  }

  .prev {
    color: $az-black;
    margin-left: $az-spacing-xxs;
  }

  .next {
    color: $az-black;
    margin-right: $az-spacing-xxs;
  }

  [disabled] {
    .prev,
    .next {
      color: $az-grey-14;
    }
  }

  .prev:hover,
  .next:hover {
    color: $az-medium-orange;
  }

  @media #{$small-and-down} {
    .prev {
      margin-left: $az-spacing-4xs;
    }

    .next {
      margin-right: $az-spacing-4xs;
    }
  }

  li.liStyle {
    list-style-type: none;
    display: inline-block;
  }

  ul.listStyle {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
