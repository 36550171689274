@import '../../../../theme/newVariables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $az-spacing-xs;
}

.mainImage {
  width: $az-spacing-512;
  height: $az-spacing-512;

  @media #{$extra-large-and-up} {
    width: $az-spacing-449;
    height: $az-spacing-449;
  }

  @media #{$small-and-down} {
    width: $az-spacing-288;
    height: $az-spacing-288;
  }

  img {
    max-width: $az-percent-100;
    height: auto;
  }
}

.thumbnailContainer {
  width: $az-spacing-449;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $az-spacing-xxs $az-spacing-28 $az-spacing-zero;

  @media #{$medium-only} {
    width: $az-spacing-512;
    margin-bottom: $az-spacing-m;
  }

  @media #{$small-and-down} {
    width: $az-spacing-288;
    justify-content: center;
    align-items: center;
  }
}

.mobileFilledDot {
  width: $az-spacing-6;
  height: $az-spacing-6;
  border-radius: $az-spacing-3;
  background: $az-grey-8;
  margin: $az-spacing-zero $az-spacing-5xs;
}

.mobileEmptyDot {
  margin: $az-spacing-zero $az-spacing-5xs;
  width: $az-spacing-10;
  height: $az-spacing-10;
  border-radius: $az-spacing-5;
  border: $az-spacing-1 solid $az-grey-8;
}
