@import '@/theme/newVariables';

.drawerRoot {
  z-index: 1601;
}

.drawer {
  background-color: #e5e5e5;
  height: 100%;
  overflow-x: hidden;
  @media #{$small-and-down} {
    width: 100%;
    max-width: 100%;
  }
}

.loaderContainer {
  background: white;
  max-width: 424px;
  width: 424px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media #{$small-and-down} {
  .loaderContainer {
    width: 100vw;
  }
}
