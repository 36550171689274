@import '../../../../theme/newVariables';

.container {
  @media #{$small-and-down} {
    margin-top: $az-spacing-xs;
  }
}

.skeletonMargin {
  margin-top: $az-spacing-4xs;
}

.skeletonPriceAndQuantityContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: $az-spacing-36;

  @media #{$small-and-down} {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: $az-spacing-s;
  }
}

.skeletonPriceContainer {
  display: flex;
  flex-direction: row;
}

.skeletonDollarSign {
  width: $az-spacing-45;
  height: $az-spacing-34;
  color: $az-black;
  font-size: $az-spacing-45;
  font-family: $font-az-cond-heavy;
  text-transform: uppercase;
  letter-spacing: $az-spacing-half;
  line-height: $az-spacing-34;
  margin-right: $az-spacing-10;
}

.skeletonQuantityContent {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media #{$small-and-down} {
    margin-top: $az-spacing-m;
  }
}

.skeletonQuantityCircle {
  background: $az-white;
  border-radius: $az-spacing-15;
  border: $az-spacing-1 solid $az-grey-3;
  height: $az-spacing-30;
  width: $az-spacing-30;
}

.skeletonQuantitySquare {
  width: $az-spacing-xxxl;
  height: $az-spacing-l;
  background: $az-white;
  border-top: $az-spacing-1 solid $az-grey-3;
  border-left: $az-spacing-1 solid $az-grey-3;
  border-right: $az-spacing-1 solid $az-grey-3;
  border-bottom: $az-spacing-1 solid $az-grey-8;
  border-radius: $az-spacing-zero;
  margin: $az-spacing-zero $az-spacing-xxs;
}

.skeletonDeliverOptionContainer {
  display: flex;
  flex-direction: row;
}

.skeletonDeliverOptionSecondItem {
  margin-top: 16px;
}

.skeletonDeliverOptionCircle {
  width: $az-spacing-xs;
  height: $az-spacing-xs;
  background: $az-white;
  border: $az-spacing-1 solid $az-grey-3;
  border-radius: $az-spacing-10;
  margin-right: $az-spacing-xxs;
}

.skeletonBlackBox {
  margin-top: $az-spacing-m;
  width: 100%;
  height: $az-spacing-50;
  background: $az-black;
  border-radius: 4px;
}

.skeletonSplitSquares {
  display: flex;
  flex-wrap: row-wrap;
  justify-content: flex-start;
  gap: 8px;
}

.horizontalSpacing {
  margin: $az-spacing-m $az-spacing-zero;

  @media #{$medium-only} {
    margin: $az-spacing-m $az-spacing-neg-88;
  }
}
