@import '@/theme/newVariables';

.mainContainer {
  line-height: $az-spacing-s;
  padding: $az-spacing-xxs;
  background: white;
  border-radius: 0.3em;
  margin: $az-spacing-4xs $az-spacing-xxs;
}
.topContainer,
.bottomContainer {
  justify-content: space-between;
}

.address {
  max-width: 200px;
}

.flex {
  display: flex;
}
.flexRow {
  flex-direction: row;
}
.flexColumn {
  flex-direction: column;
}
.flexWrap {
  flex-wrap: wrap;
}
.flexBasis50 {
  flex-basis: 50%;
}

.storeDetails {
  align-items: end;
  font-size: 14px;
  color: $az-black;
  line-height: $az-spacing-18;
  font-weight: 400;
}

.getDirectionLink {
  font-size: 14px;
  color: $az-black;
  text-decoration: underline;
  font-weight: 400;
}

.pickupDay {
  font-weight: 700;
}

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: white;
  color: #202124;
  height: $az-spacing-xxl;
  @media #{$small-and-down} {
    width: 100vw;
    color: white;
    background: #202124;
  }
}

.header {
  font-family: $font-az-helvetica;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.flyoutBodyMain {
  background-color: white;
  padding: 16px;
  margin-bottom: $az-spacing-4xs;
  width: 100%;
  height: auto;
  & .productContainer {
    display: flex;
    padding: 16px 16px;
    & .productName {
      font-size: 14px;
      color: #000;
      margin-left: 16px;
      word-wrap: normal;
      width: 250px;
    }
    & .subHeading {
      color: #5b5d5b;
      font-size: 12px;
      margin-top: 4px;
    }
  }
  & .strikeThroughContainer {
    display: flex;
    padding: 16px 8px;
    & .productName {
      font-size: 14px;
      color: #000;
      margin-left: 16px;
      word-wrap: normal;
      width: 250px;
    }
    & .subHeading {
      color: #5b5d5b;
      font-size: 12px;
      margin-top: 4px;
    }
  }
  & .extraMarginStrikeThroughPriceContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 23px;
    & .productName {
      font-size: 14px;
      color: #000;
      margin-left: 16px;
      word-wrap: normal;
      width: 250px;
    }
    & .subHeading {
      color: #5b5d5b;
      font-size: 12px;
      margin-top: 4px;
    }
  }
  .corePriceAvailable {
    color: #202124;
    font-family: $font-az-helvetica;
    font-style: normal;
    font-weight: 700;
    padding: 16px 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .corePriceLabel {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-transform: capitalize;
    color: #5b5d5b;
  }

  @media #{$small-and-down} {
    .corePriceLabel {
      font-size: 10px;
    }
    .deposit {
      color: red;
    }
  }
}

.strikeThroughPriceContainer {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.strikeThroughPrice {
  color: #5b5d5b;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 22px;
}
.drawarBody {
  max-width: 424px;
  width: 424px;
  background-color: #e5e5e5;
  height: 100%;
  @media #{$small-and-down} {
    width: 100%;
    max-width: 100%;
  }
}

.priceContainer {
  display: flex;
  padding: 16px 8px;
  justify-content: space-between;
  align-items: center;
}

.corePriceAvailablePriceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.strikeThroughPricingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}

.price {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.moreStoresContainer {
  height: 70px;
  padding: 12px;
  background-color: white;
  margin: $az-spacing-4xs 0;
  display: flex;
  align-items: center;

  & .countInfoMsg {
    margin-left: 17px;
    font-size: 14px;
  }
  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 51px;
    height: 31px;
    background: #949494;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    width: 27px;
    height: 27px;
    background: #fff;
    border-radius: 90px;
    transition: 0.03s;
  }

  .toggle {
    margin-bottom: $az-spacing-4xs;
  }
}

.flyOutClearenceWrapper {
  div {
    height: 18px;
    width: 57px;
    margin: 11px 0 0 6px;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: normal;
  }
}
.quantityError {
  font-weight: 400;
  font-size: 12px;
  color: #b30000;
}

.counterComp > div > div:first-child {
  max-height: 55px;
}

.flyoutListing {
  background: white;
  border-radius: 0.3em;
  margin: $az-spacing-4xs $az-spacing-xxs;
}

.address {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
}

.sisterStoreAddress {
  margin-top: $az-spacing-xxxs;
  font-weight: 700;
  font-size: 1em;
  line-height: 24px;
  text-transform: capitalize;
}

.sisterStoreNumber {
  font-size: 14px;
  font-weight: 400;
  margin-top: $az-spacing-s;
  margin-left: auto;
  margin-right: 0;
}

.distance {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.storePhoneNumber {
  margin-top: $az-spacing-10;
  font-size: 14px;
  text-decoration: underline;
  color: $az-black;
  line-height: $az-spacing-17;
}

.openUntil {
  color: $az-green-1;
  font-weight: 700;
  font-size: 14px;
  line-height: $az-spacing-17;
  margin-top: $az-spacing-5xs;
}

.button {
  display: flex;
  flex-basis: auto;
}

.pickup {
  font-size: 14px;
  line-height: $az-spacing-17;
}

.inStorePickup {
  line-height: $az-spacing-18;
  white-space: nowrap;
}

.outOfStock {
  font-size: 16px;
  color: #5b5d5b;
  font-weight: 700;
  line-height: 24px;
}

.inStock {
  font-size: 16px;
  color: $az-green-1;
  font-weight: 700;
  line-height: 24px;
}

.buttonClass {
  display: flex;
  justify-content: center;
  font-size: $font-size-xs;
  padding-top: $az-spacing-xxs;
}

.storeButtonClass {
  padding: $az-spacing-xxs;
  display: flex;
  justify-content: center;
  padding-top: $az-spacing-xxxs !important;
  font-size: $font-size-16;
}

.sisterButtonClass {
  padding: $az-spacing-xxs;
  display: flex;
  justify-content: center;
}

.closed {
  color: $az-medium-red;
  font-weight: 700;
  font-size: 14px;
  text-transform: capitalize;
  line-height: $az-spacing-17;
  margin-top: $az-spacing-5xs;
  text-align: right;

  &:lang(pt) {
    text-transform: none;
  }
}

.infoContainer {
  display: flex;
  align-items: center;
  padding: 10px 16px 0px 4px;
  border-radius: 4px;
  border-left-width: 4px;

  & img {
    margin-right: 8px;
  }
  & .infoLabel {
    font-size: 14px;
    font-family: $font-az-helvetica;
    font-weight: 400;
  }
}

.animate {
  max-height: 450px;
  overflow-y: hidden;
  transition: max-height 1s cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

.animateHidden {
  max-height: 0px;
}

.showMoreButtonContainer {
  background-color: $az-grey-2;
  padding-top: 6px;
  display: flex;
  flex: 1;
  justify-content: center;
  height: 250px;
}

.showMoreButton {
  border: 0;
  text-transform: uppercase;
  background-color: $az-grey-2;
  color: $az-black;
  font-family: 'AutoZoneCond', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  height: 27px;
  text-decoration: underline;
}

.showMoreButton:focus {
  background-color: $az-grey-2;
}

.sisterStoreList {
  background-color: $az-grey-2;
}

.quantityExceed {
  margin-top: 16px;
  animation: slideIn 0.4s ease-in;
}
@keyframes slideIn {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

.closeButton {
  padding: 8px;
}
.pickup,
.storeDetailsFlexView {
  flex-direction: row;
  justify-content: space-between;
}

.storeDetailsFlexView {
  margin-top: -1px;
}

.loaderContainer {
  max-width: 424px;
  width: 424px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $az-white;
}

@media #{$small-and-down} {
  .loaderContainer {
    width: 100vw;
  }
}
