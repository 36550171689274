@import '../../../theme/newVariables';

.contentDivider {
  @media #{$extra-large-and-up} {
    background: $az-white;
    box-shadow: $az-spacing-1 $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-grey-2;
    border-radius: $az-spacing-zero;
  }
}

.productSpecificationWrapper {
  margin-top: $az-spacing-m;
  border-top: $az-spacing-1 solid $az-grey-2;
  padding: $az-spacing-xxxl $az-spacing-xs $az-spacing-zero $az-spacing-xs;

  @media #{$medium-only} {
    margin-top: $az-spacing-l;
    padding: var(--st-unit-10) var(--st-unit-4) 0 var(--st-unit-4);
  }

  @media #{$small-and-down} {
    margin-top: $az-spacing-m;
    padding: $az-spacing-zero $az-spacing-zero;
  }

  .productSpecificationPart {
    h3 {
      @media #{$extra-large-and-down} {
        padding-left: $az-spacing-xxs;
      }

      @media #{$small-and-down} {
        margin-bottom: $az-spacing-zero;
      }
    }
  }

  .productDescriptionPart {
    @media #{$extra-large-and-up} {
      padding-left: $az-spacing-s;
    }

    @media #{$small-and-down} {
      padding: $az-spacing-zero $az-spacing-xxs;

      h3 {
        padding-top: $az-spacing-m;
        border-top: $az-spacing-1 solid $az-grey-3;
      }
    }
    @media #{$medium-only} {
      padding: $az-spacing-zero $az-spacing-xxs;
    }
  }

  .productSpecificationTitle {
    font-weight: 500;
    line-height: 1.6;

    @media #{$small-and-down} {
      color: $az-black;
      margin-left: $az-spacing-2;
    }
  }

  .productDescriptionTitle {
    font-weight: 500;
    line-height: 1.6;

    @media #{$extra-large-and-up} {
      padding-left: $az-spacing-38;
      padding-bottom: $az-spacing-15;
    }
    @media #{$medium-only} {
      padding-left: $az-spacing-2;
      padding-bottom: $az-spacing-15;
    }
    @media #{$small-and-down} {
      color: $az-black;
      padding-left: $az-spacing-2;
    }
  }
}

.productSpecificationBody > tr > * {
  border-bottom: none;
}

.productServiceBulletinLinks {
  color: $az-black;
  text-decoration: underline;
}

.specsTableContainer {
  @media #{$extra-large-and-up} {
    padding-right: $az-spacing-4xs;
    border-right: $az-spacing-1 solid $az-grey-2;
  }
}

.notesDescription {
  font-style: italic;

  @media #{$small-and-down} {
    padding-right: $az-spacing-xxs;
    display: inline-block;
  }
}

.description {
  padding: 0 $az-spacing-xxs 0 $az-spacing-xxs;

  @media #{$medium-only} {
    padding: 0 $az-spacing-xxs 0 $az-spacing-2;
  }
}
.productSpecHeaderContainer,
.productDescHeaderContainer {
  @media #{$small-and-down} {
    padding: $az-spacing-4xs $az-spacing-xxxs $az-spacing-1 0;
  }
}
.warranty {
  border: none;
  background-color: transparent;
  padding-left: $az-spacing-zero;
  @media #{$medium-only} {
    color: $az-black;
  }
  @media #{$small-and-down} {
    color: $az-grey-6;
  }
}

.feature {
  padding-top: $az-spacing-xxxs;
}

.featurelist {
  padding: $az-spacing-4xs $az-spacing-zero;
  display: flex;
}

.checkmark {
  flex-shrink: $az-spacing-zero;
}
.readMoreMobileBtnContainer {
  @media #{$small-and-down} {
    text-align: $rdirection;

    .readMoreButton {
      padding-left: $az-spacing-zero;
      margin-right: $az-spacing-xxs;
    }
  }
}
.readMoreButton {
  background: none;
  border: $az-spacing-zero;
  float: $rdirection;
  padding: $az-spacing-zero;
  margin: $az-spacing-m $az-spacing-zero $az-spacing-l $az-spacing-zero;

  @media #{$extra-large-and-up} {
    padding-right: $az-spacing-4xs;
  }

  @media #{$small-and-down} {
    margin: $az-spacing-xs $az-spacing-zero $az-spacing-l $az-spacing-zero;
    color: $az-grey-5;
    text-decoration: underline;
    cursor: pointer;
  }
  @media #{$medium-only} {
    color: $az-black;
  }

  .arrowReadless {
    display: none;

    @media #{$extra-large-and-down} {
      display: inline-block;
      position: relative;
      bottom: $az-spacing-2;
    }
  }
}

.readMoreArrow {
  display: none;

  @media #{$extra-large-and-down} {
    display: inline-block;
  }
}

.readMoreSmall,
.readMoreSmallProdDesc {
  @media #{$extra-large-and-down} {
    display: block;
    text-align: $cdirection;
  }

  button {
    @media #{$extra-large-and-down} {
      float: none;
    }
  }
}

.mobileDesc {
  height: $az-spacing-110;
  overflow: hidden;
}

.productDescriptionButtons {
  margin: $az-spacing-zero $az-spacing-zero $az-spacing-m $az-spacing-zero;

  @media #{$extra-large-and-up} {
    margin: $az-spacing-zero;
  }

  @media #{$small-and-down} {
    margin: $az-spacing-zero $az-spacing-zero $az-spacing-l $az-spacing-zero;
  }
}

.readMoreButtonContainer {
  display: flex;
  justify-content: center;

  @media #{$extra-large-and-up} {
    justify-content: flex-end;
    margin-right: $az-spacing-1;
  }

  @media #{$small-and-down} {
    justify-content: flex-end;
    padding-right: $az-spacing-xxs;
  }
}

.bulletinsValue {
  a {
    color: rgba(0, 0, 0, 0.87);

    @media #{$small-and-down} {
      color: inherit;
    }
  }
}
