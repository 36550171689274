@import '../../../theme/newVariables.scss';

.counterpart {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  @media #{$small-and-down} {
    align-items: flex-start;
  }
}

.alignEachItemPriceWithError {
  text-align: left;
}

.unitPrice {
  color: $az-grey-5;
}

.cartClass {
  display: flex;
  justify-content: center;

  & > div {
    // match width of CommonCounter sibling
    width: calc(var(--st-unit-1) * 29);
  }
}

.mxCart {
  max-width: 116px;
}

.quantityAvailableError {
  color: $az-medium-red;
  margin-bottom: $az-spacing-5;
  max-width: $az-spacing-156;
}

@media print {
  .counterpart {
    float: $ldirection;
  }
}
