@import '../../theme/newVariables.scss';

.productPastContainer {
  margin-top: $az-spacing-xxs;
}

.fulfillmentOptions {
  margin-bottom: $az-spacing-xxs;
  box-shadow: inset $az-spacing-neg-1 $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-white;
  background-color: $az-grey-1;
}

.fullWidthinMobile {
  @media #{$small-and-down} {
    width: 100%;
  }

  /* Firefox specific horizontal scroll fix */
  flex-direction: unset;
}
