@use '@/theme/newVariables' as *;

.configurableSkuBadge {
  display: flex;
  align-items: center;
  gap: var(--st-unit-1);
  margin-bottom: var(--st-unit-1);

  .text {
    font-size: 14px;
    line-height: 19.6px;

    @media #{$small-and-down} {
      font-size: 12px;
    }
  }
}
