@import '@/theme/newVariables';

.productDeal {
  background-color: $az-white;
  padding: $az-spacing-xxs;

  @media #{$small-and-down} {
    margin-bottom: $az-spacing-4xs;
    width: auto;
  }
}

.productImg {
  img {
    width: $az-spacing-68;
    height: $az-spacing-68;
  }
}

.productHeader {
  padding-bottom: $az-spacing-xs;

  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: $text-flow-mode;
    -o-text-overflow: $text-flow-mode;
    -ms-text-overflow: $text-flow-mode;
    text-align: $ldirection;

    @media #{$small-and-down} {
      font-size: $font-size-20;
    }
  }
}

.productInfo {
  display: flex;
  margin-bottom: $az-spacing-xxs;
}

.productDescription {
  height: $az-spacing-100;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: $text-flow-mode;

  @media #{$medium-and-down} {
    height: $az-spacing-60;
  }
}

.expiresData {
  font-weight: bold;
  margin-top: $az-spacing-4xs;
}

.dealButton {
  position: relative;
  width: $az-percent-100;
}
