@import '../../../../theme/newVariables.scss';

.readmore {
  background: none;
  border: none;
  margin: $az-spacing-zero;
  padding: $az-spacing-zero;
  text-align: $ldirection;
  color: $az-black;
  text-decoration: underline;
}

.note {
  overflow: hidden;
  text-overflow: $text-flow-mode;
  margin-top: $az-spacing-xxs;
  margin-bottom: $az-spacing-4xs;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.superSku {
  margin: 0;
}
