@import '../../../theme/newVariables';

.discountSection {
  display: flex;
  flex-direction: column;
  &.cart {
    margin-bottom: 4px;
  }
}
.discountSectionWCorePrice {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
  &.cart {
    margin-bottom: 4px;
  }
}

.strikeprice {
  text-decoration: line-through;
  color: $az-grey-14;
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;

  @media #{$medium-and-up} {
    font-size: $font-size-12;
  }
}

.discount {
  color: $az-color-success;
  font-family: $font-az-helvetica;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  @media #{$medium-and-up} {
    font-size: 16px;
    line-height: 20px;
  }
}

.alignRightStrikethrough {
  text-decoration: line-through;
  color: $az-grey-14;
  font-size: $font-size-14;
  font-weight: 400;
  text-align: right;
  margin-left: 8px;
}
