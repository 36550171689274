@import '../../../../theme/newVariables.scss';

@media #{$small-and-down} {
  .titleContainer {
    margin-bottom: $az-spacing-xxxs;
  }
}
.productTitle {
  color: $az-black;
}

.shareLinkContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.shopAllBrandNameText {
  margin-bottom: $az-spacing-5xs;
  display: inline-block;
  color: $az-black;
  text-decoration: underline;
  font-family: $font-az-helvetica;
  font-size: 12px;
  letter-spacing: $az-spacing-point-four;
  line-height: $az-spacing-22;
  font-weight: 500;
  font-style: normal;
}
