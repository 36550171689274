@import '../../../theme/newVariables';

.quantityMainContainer {
  display: flex;
  align-items: $cdirection;

  &.cartPageQuantityContainer {
    justify-content: flex-end;

    @media #{$small-and-down} {
      margin-top: unset;
    }
  }

  .quantityContainer {
    display: flex;
    align-items: $cdirection;

    .inputStyleMoreMargin {
      width: $az-spacing-58;
      height: $az-spacing-l;
      text-align: $cdirection;
      margin: $az-spacing-zero $az-spacing-xxs;

      &:lang(pt) {
        width: 64px;
        height: 45px;
        border-radius: 4px;
      }
    }

    .inputStyle {
      width: $az-spacing-l;
      height: $az-spacing-l;
      text-align: $cdirection;
      margin: $az-spacing-zero $az-spacing-4xs;
    }
  }

  .inputStyleStrikeThrough {
    width: $az-spacing-63;
    height: $az-spacing-45;
    text-align: $cdirection;
    margin: $az-spacing-zero $az-spacing-18;
  }

  .qtyTextBox {
    border-top: solid $az-spacing-1 $az-grey-2;
    border-left: solid $az-spacing-1 $az-grey-2;
    border-right: solid $az-spacing-1 $az-grey-2;
    padding: calc(#{$az-spacing-5xs} - #{$az-spacing-2});

    &:lang(pt) {
      font-size: 16px;
      font-weight: 300;
    }

    &:focus,
    &:active {
      border-top: solid $az-spacing-1 $az-blue-9;
      border-left: solid $az-spacing-1 $az-blue-9;
      border-right: solid $az-spacing-1 $az-blue-9;
      border-radius: 2px;
      outline: solid 1px $az-blue-9;
    }
  }

  .normalInput {
    border-bottom: solid $az-spacing-5xs $az-grey-4;

    &:lang(pt) {
      border-bottom: solid $az-spacing-2 $az-grey-4;
    }
  }

  .errorInput {
    border-bottom: solid $az-spacing-5xs $az-color-error;
  }

  .round {
    background: $az-white;
    border: solid $az-spacing-1 $az-grey-2;
    min-height: calc(#{$az-spacing-l} - #{$az-spacing-5xs});
    width: calc(#{$az-spacing-l} - #{$az-spacing-5xs});
    border-radius: $az-percent-50;
    padding: $az-spacing-zero;

    img {
      vertical-align: middle;
    }

    &:disabled {
      background: $az-grey-1;
      cursor: default;
    }
  }
}

.quantityBlockContainer {
  display: block;
}

@media print {
  .quantityMainContainer {
    .round {
      display: none;
    }
  }
}
