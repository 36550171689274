.container {
  margin-bottom: 0;
  height: auto;
  padding-left: calc(24px + 11px); // checkbox width + comps padding
  & > span {
    border-radius: 0;
    top: unset;
  }
}

.label {
  display: inline;
  * {
    font-size: 14px;
  }
}

.productName {
  margin-right: 11px;
}

.link {
  color: var(--st-color-primary);
  text-decoration: underline;
}
