@import '@/theme/newVariables';

.vehicleSection {
  padding: 16px;
}

.section {
  margin-bottom: 16px;
}

.sectionTitle {
  font-size: 16px;
  font-weight: bold;
}

.sectionDescription {
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: 12px;
}
