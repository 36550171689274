@import '@/theme/newVariables.scss';

.rebateWrapper {
  display: flex;
  margin-top: 8px;

  @media #{$small-and-down} {
    margin-bottom: 16px;
  }
  &.newFulfillmentRebate {
    @media #{$small-and-down} {
      margin-bottom: 0;
    }
  }
}

.rebateIcon {
  padding-right: 8px;
}

.rebates {
  color: $az-dark-black;
  background: transparent;
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
}
