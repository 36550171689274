@import '@/theme/newVariables';

.reviewsHeader {
  @media #{$small-and-down} {
    padding-left: 4px;
    color: $az-black;
  }
}

.reviewsSummary {
  display: flex !important;
  outline: none;

  &.reviewsSummary {
    padding: $az-spacing-zero $az-spacing-xxs;
    align-items: flex-start;
  }
}

.pdpWrapper {
  .expansionPanelSummary {
    padding: $az-spacing-zero;
    margin: $az-spacing-zero $az-spacing-xxs;
    align-items: center;
    border-bottom-color: $az-grey-3;
  }
  .expansionPanelIcon {
    width: $az-spacing-s;
  }
  .expansionPanelDetails {
    display: block;
  }
}

.reviewsContent {
  flex: 1;
  max-width: $az-percent-100;
}

.reviewsLinkContainer {
  .reviewsLink {
    display: inline-flex;
    align-items: center;
    gap: $az-spacing-4xs;
    margin: $az-spacing-10 $az-spacing-zero $az-spacing-zero $az-spacing-xxs;
  }
}
