@import '@/theme/newVariables.scss';

.skeletonContainer {
  position: relative;
}

.partsContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;

  .partCard {
    display: flex;
    gap: 12px;
    padding: 8px;
    background-color: $az-white;
    box-shadow: 0px 1px 2px 0px #20212433, 0px 1px 5px 0px #2021241f;

    @media #{$large-and-up} {
      min-width: 343px;
      max-width: 343px;
    }

    @media #{$small-to-medium} {
      min-width: 332px;
      max-width: 332px;
    }

    @media #{$small-and-down} {
      min-width: 312px;
      max-width: 312px;
    }

    .textSkeletonSection {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .innerRow {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .descriptionColumn {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
      }

      .buttonColumn {
        display: flex;
        align-items: flex-end;

        .buttonSkeleton {
          background-color: $az-grey-16;
        }
      }
    }
  }
}

.textSkeleton {
  min-height: unset;
}

.skeletonArrows {
  position: absolute;
  top: 0;
  right: -10px;
  bottom: 0;
  display: flex;
  align-items: center;

  @media #{$small-and-down} {
    display: none;
  }
}
