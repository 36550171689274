@import '../../../../theme/newVariables';

.infoContainer {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #007faf;
  border-radius: 4px;
  border-left-width: 4px;

  & img {
    margin-right: 8px;
  }
  & .infoLabel {
    font-size: 14px;
    font-family: $font-az-helvetica;
    font-weight: 400;
  }
}
