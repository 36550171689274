@import '@/theme/newVariables.scss';

.popularSearchesInnerContainer {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 20px;
}

.popularSearchesOuterContainer {
  margin-top: 20px;
}

.popularSearchHeading {
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  margin-bottom: 12px;
}

.popularSearchLink {
  font-family: Helvetica Neue, Arial, sans-serif;
  font-size: 13.5px !important;
  line-height: 22.5px !important;
  font-weight: 500 !important;
  display: inline-block;
  color: #5b5d5b;
  margin-bottom: 12px;
  text-transform: capitalize !important;
}

@media #{$medium-and-down} {
  .popularSearchesInnerContainer {
    padding-top: 20px;
    padding-bottom: 8px;
  }
}

@media #{$small-and-down} {
  .popularSearchesInnerContainer,
  .popularSearchLink {
    display: block;
  }

  .popularSearchLink {
    font-size: 12px !important;
  }

  .popularSearchHeading {
    margin-bottom: 12px;
  }
}
