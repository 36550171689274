@import '@/theme/newVariables';

.miniCartContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $az-grey-2;

  @media #{$medium-and-up} {
    max-width: 424px;
    width: 424px;
  }
}

.wrapperContainer {
  margin: 0.8rem;
  background-color: $az-white;
}
.productContainer {
  background-color: $az-white;
}
.productContainerBorderLine {
  margin-top: 4px;
}
.wrapperCheckoutContainer {
  background-color: $az-white;
  margin: 4px 0 8px 0;
  padding: $az-spacing-xxs;

  .freeShippingNote {
    margin-bottom: $az-spacing-19;
  }
}

.wrapperContainerWithShippingPromo {
  background-color: $az-white;
  margin: var(--st-unit-2) 0;
  padding: var(--st-unit-3) var(--st-unit-4);

  .freeShippingNote {
    margin-bottom: var(--st-unit-3);
  }
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: $az-black;
  color: $az-white;
  height: 56px;
}

.navBarContainer {
  font-family: $font-az-helvetica-heavy;
  font-weight: 700;
  font-size: $font-size-12;
  line-height: 18px;
  padding: 16px;
  height: $az-spacing-xxl;
  color: $az-black;
  background-color: $az-white;
  @media #{$small-and-down} {
    background-color: $az-black;
  }
}

.navBarCloseIcon {
  padding: 12px;
  border-radius: 50%;
  color: $az-black;
  @media #{$small-and-down} {
    color: $az-white;
  }

  &:hover,
  &:focus {
    background-color: rgba(250, 250, 250, 0.04);

    @media (hover: none) {
      background-color: transparent;
    }
  }
}

.productTitle,
.addedToCart,
.total {
  font-family: $font-az-helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: $font-size-15-75;
  line-height: 22px;
}

.checkMark {
  padding: 0.1rem;
}

.addedToCart {
  margin-left: 0.5rem;
}

.displayFlex {
  display: flex;
  margin-left: 0.8rem;
  margin-top: 16px;
}

.wrapperItems {
  height: auto;
  display: grid;
  position: relative;
  border: 1px solid $az-grey-3;
  border-radius: 4px;
  grid-auto-rows: 20px;
  padding: 16px;
  grid-auto-rows: auto;
  grid-template-areas:
    'a b c'
    'a b c'
    'a b c'
    'a b c';
}

@media #{$small-and-down} {
  .wrapperItems {
    width: 100%;
  }
}

.image {
  grid-area: a;
  align-self: center;
  justify-self: center;
}

.description {
  grid-area: b;
  align-self: center;
  padding: 0 0.5rem 0.5rem 0.5rem;
  justify-self: center;
  font-family: $font-az-helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: $font-size-14;
  line-height: 20px;
}

.priceQuantityWrapper {
  padding-left: 0.65rem;
  border-left: 1px solid $az-grey-3;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-area: c;
  align-self: center;
}

.quantity {
  grid-area: c;
  padding: 0 0 0 1rem;
  justify-self: end;
  font-family: $font-az-helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: $font-size-14;
  line-height: 14px;
}

.quantity > span {
  font-weight: 700;
}

.strikeThroughPrice {
  grid-area: s;
  padding: 8px 0 0 8px;
  justify-self: end;
  line-height: 22px;
  @media #{$small-and-down} {
    line-height: 20px;
  }
}

.coreDeposit {
  line-height: 22px !important;
  @media #{$small-and-down} {
    line-height: 20px !important;
  }
}
.eachPrice {
  grid-area: e;
  font-size: $font-size-12;
  justify-self: end;
  padding-bottom: 8px;
}

.unitPrice {
  font-size: $font-size-12;
}
.clearance {
  position: absolute;
  width: 57px;
  height: 18px;
  top: 0;
  left: 0;
  font-family: $font-az-helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  background-color: $az-grey-0;
  line-height: 14px;
  text-align: center;
  border-radius: 4px;
  padding: 2px 4px;
}

.eachPrice > div {
  margin-top: 0;
}

.coreDepositMx {
  text-align: right;
  padding-left: 10px;
}

.drawarBody {
  height: 100%;
}

.cartTotalCheckoutBtn {
  display: flex;
  justify-content: center;
  button {
    width: auto;
  }
}
.miniCartText {
  font-size: $font-size-16;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin-bottom: $az-spacing-xxs;
  line-height: $font-size-24;
}
.miniCartTextWithShippingPromo {
  font-size: $font-size-16;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin-bottom: $az-spacing-4xs;
  line-height: $font-size-24;
}
.continueShoppingLink {
  margin-top: $az-spacing-s;
  display: flex;
  justify-content: center;
  button {
    color: $az-black;
    border: none;
    text-decoration: underline;
    background: none;
    font-weight: 500;
    font-size: $font-size-14;
    line-height: $az-spacing-xs;
  }
}

.itemListText {
  margin-left: $az-spacing-5xs;
  font-weight: 400;
  font-size: $az-spacing-xxxs;
  font-family: $font-az-helvetica;
}

.certonaMiniCartFlyout {
  overflow-x: hidden;
  padding: $az-spacing-xxs;
  background-color: $az-white;
}

.loadingSpinner {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.dealImagesContainer {
  margin-top: 11px;
  margin-bottom: 16px;
  padding: 8px 8px 8px 33px;
  display: flex;
  flex-wrap: wrap;

  .dealImage {
    margin-right: 8px;
    position: relative;

    .dealItemQuantityBadgeContainer {
      height: 14px;
      width: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: $az-black;
      color: #fff;
      padding: 0 4px;
      position: absolute;
      bottom: 1px;
      right: -7px;
      text-align: center;
      font-family: $font-az-helvetica;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .badgeMargin {
    margin-right: 16px;
  }
}
