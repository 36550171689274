@import '@/theme/newVariables';

$related-part-checkbox-name-height: 24px;

.relatedPartsCheckboxNameList {
  display: flex;
  flex-direction: column;
  row-gap: var(--st-unit-4);
  column-gap: var(--st-unit-4);
  width: 100%;
  margin-bottom: var(--st-unit-6);

  @media #{$extra-large-and-up} {
    margin-bottom: 0;
    &.desktopListWrap {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      // Makes sure that we have 3 rows per column
      max-height: calc($related-part-checkbox-name-height + 16px) * 3;
    }
  }
}
