// TODO: remove when starc media query implemented
@import '@/theme/newVariables';

// TODO: Refer to STARC docs when updated how to implement STARC media queries
// @media (--st-viewport-s) {
@media #{$medium-and-up} {
  .descriptionContainer {
    background: url('/images/dashlights-pattern.png') -200px -100px/1334px auto repeat-y;
  }
}
