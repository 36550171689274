@import '../../../../theme/newVariables.scss';

.radioFormControl {
  // To overwrite Material Radio Button CSS,Need to add below code with important
  margin-right: $az-spacing-zero !important;
  justify-content: flex-start;
  align-items: flex-start !important;
  width: 100%;

  .radioLabelDisabled {
    text-decoration: line-through;
    opacity: 0.5;
  }
}

.radioLabel {
  flex: 1;
}

.radioHiddenContainer {
  margin-right: $az-spacing-47;
  margin-top: $az-spacing-neg-8;
}

.radioContainer {
  margin-right: $az-spacing-7;
  margin-top: $az-spacing-neg-8;

  .radioRoot {
    color: $az-grey-3;

    &:hover {
      color: $az-black;
    }
  }

  .radioRootDisabled {
    color: $az-grey-2;
  }

  .radioChecked {
    color: $az-black;
  }

  .radioCheckedDisabled {
    color: $az-grey-2;
  }

  .radioDisabled {
    background: $az-grey-2;
    border-radius: $az-percent-50;
    position: absolute;
    width: $az-spacing-xs;
    height: $az-spacing-xs;
    border: solid $az-spacing-1 $az-grey-3;

    @media #{$small-and-down} {
      width: $az-spacing-xs;
      height: $az-spacing-xs;
    }

    @media #{$extra-large-and-up} {
      margin: auto;
      top: $az-spacing-zero;
      bottom: $az-spacing-zero;
      right: $az-spacing-zero;
      left: $az-spacing-zero;
    }
  }

  .iconSize {
    @media #{$small-and-down} {
      width: $az-spacing-xs;
      height: $az-spacing-xs;
    }
  }
}
