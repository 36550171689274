.root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: -11px;
  margin-right: 16px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.disabled {
  cursor: default;
}

.labelPlacementStart {
  margin-left: 16px;
  margin-right: -11px;
  flex-direction: row-reverse;
}

.labelPlacementTop {
  margin-left: 16px;
  flex-direction: column-reverse;
}

.labelPlacementBottom {
  margin-left: 16px;
  flex-direction: column;
}

.label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
