@use '@/theme/newVariables' as *;

.comboDealsButtonContainer {
  background-color: #fcdfcc;
  border-radius: $az-spacing-5xs;
  display: flex;
  align-items: center;
  width: auto;

  &:focus {
    background-color: #fcdfcc;
  }
}

.dealBundleButton {
  color: $az-dark-black;
  font-size: 12px;
}

.comboDealsButtonInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 4px 8px;
  font-size: 14px;
  font-family: $font-az-helvetica;
  color: $az-black;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-decoration: underline;
  text-align: left;

  &:hover {
    text-decoration: none;
  }

  @media #{$small-and-down} {
    font-size: 12px;
    line-height: 20px;
  }
}

.comboDealsIcon {
  color: $az-medium-orange;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $az-spacing-4xs;

  > svg {
    position: relative;
    max-width: 24px;
    max-height: 24px;
    min-width: 24px;
    min-height: 24px;
    font-size: 1rem;
  }
}
