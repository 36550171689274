@import '../../../theme/newVariables';

.success {
  margin-left: $az-spacing-4xs;
  animation-duration: 500ms;
  animation-name: opacity;
  animation-iteration-count: 1;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
