@import '@/theme/newVariables';

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.errorContainer {
  padding: 16px;
}

.drawer {
  width: 424px;
  overflow: hidden;
  @media #{$small-and-down} {
    width: 100%;
  }
}

.bundleDrawerBody {
  height: 100%;
}

.navBarInDealDetails {
  justify-content: space-between;
}

.drawerBackButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.bundleTitle {
  font-size: 22px;
  font-weight: 300;
}

.combodealsMarginBottom {
  margin-bottom: 8px;
}
