@import '../../../theme/newVariables.scss';

.vehicleFit.fitmentButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $az-color-warning-light;
  border: none;
  border-bottom: $az-spacing-2 solid $az-color-caution;
  cursor: pointer;
  font-size: $font-size-13-5;
  padding: $az-spacing-4xs $az-spacing-xxxs;
  min-height: $az-spacing-l;
  text-align: $ldirection;
  width: $az-percent-100;

  &.fitsVehicle {
    border-color: $az-color-success;
    color: $az-medium-green;
    background-color: $az-light-green;
  }

  &.fitsVehicleError {
    border-color: $az-color-error;
    color: $az-medium-red;
    background-color: $az-light-red-2;
  }

  .imgContainer {
    display: flex;
    min-width: $az-spacing-30;
    position: relative;
    margin-bottom: $az-spacing-5xs;
    .imgSmall {
      height: 10px;
      width: 10px;
      position: relative;
      display: flex;
      right: 7px;
      top: 7px;
    }
  }

  .descContainer {
    text-transform: initial;
    font-size: $font-size-14;
    color: $az-black;
    .underlined {
      text-decoration: underline;
    }
    .successText {
      color: $az-medium-green;
    }
    .errorText {
      color: $az-color-error;
    }
    .errorPipe {
      padding: 0 $az-spacing-5;
    }
    .errorBtn {
      display: flex;
      flex-wrap: wrap;
      gap: 0 $az-spacing-10;
    }
    @media #{$small-and-down} {
      .seeFittingPartsBtn {
        padding: $az-spacing-13 0;
        margin: $az-spacing-neg-13 0;
      }
      .changeVehicle {
        z-index: 2;
        padding-top: $az-spacing-13;
      }
      .errorText {
        line-height: 1.5;
      }
    }
  }

  .warningIcon {
    margin-right: $az-spacing-4xs;
    width: $az-spacing-14;
    height: 15.75px;
  }
}
