@import '@/theme/newVariables.scss';

.priceSkeleton {
  transform: none;
}

.coreChargePlusCoreStyle {
  font-size: $font-size-12;
  line-height: 28px;
  margin-left: 11px;
  color: $az-grey-11;
  width: 51px;
  text-align: right;
}

.quantityContainer {
  @media #{$small-and-down} {
    margin-top: $az-spacing-xs;
    margin-bottom: $az-spacing-xs;
  }
}

.priceQuantityWrapper {
  display: flex;
  justify-content: space-between;
  width: $az-percent-100;
  @media #{$small-and-down} {
    flex-direction: column;
  }
  @media #{$medium-and-up} {
    align-items: center;
  }
}

.makeColumn {
  @media #{$small-and-down} {
    flex-direction: column;
  }
}

.quantityMargin {
  margin-bottom: $az-spacing-xxs;

  @media #{$small-and-down} {
    margin-bottom: $az-spacing-xs;
  }
}

.strikethroughPriceWrapper {
  display: flex;
  align-items: flex-end;

  .marginLeft {
    margin-left: 8px;
  }
}
