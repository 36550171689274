@import '@/theme/newVariables';

.drawerNavBarMainContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 12px;

  @media #{$small-and-down} {
    padding: 6px 0 6px 8px;
    background-color: #202124;
  }
}

.leftControls {
  flex: 1 1 auto;
}

.closeIcon {
  flex: 0 0 auto;

  @media #{$small-and-down} {
    color: $az-white;
  }
}

.backButton {
  border: none;
  background: none;
  padding: $az-spacing-zero;
  cursor: pointer;
  display: flex;
  line-height: $az-spacing-18;
  font-size: $font-size-16;
  font-family: $font-az-cond-medium;
  letter-spacing: $az-spacing-three-fourths;
  text-transform: uppercase;

  @media #{$small-and-down} {
    color: $az-white;
  }
}

.backIcon {
  font-size: $font-size-18 !important;
  margin-right: $az-spacing-5;

  @media #{$small-and-down} {
    color: $az-white;
  }
}
