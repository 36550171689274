@import '../../theme/newVariables';

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.errorPageContainer {
  width: $az-percent-100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media #{$medium-and-up} {
    flex-direction: row;
  }
}

// MOBILE

.errorPageHeadingContainer {
  display: flex;
  flex-direction: column;
  width: $az-percent-100;
  padding: $az-spacing-xs $az-spacing-4xs $az-spacing-4xs $az-spacing-xs;
}

.errorPageMobileHeading {
  padding: $az-spacing-zero $az-spacing-zero $az-spacing-s $az-spacing-zero;
  font-family: $font-az-cond-heavy;
  font-size: $font-size-40;
}

.errorPageSubText {
  font-size: $font-size-14;
  display: flex;
}

.errorPageBtnContainer {
  padding: $az-spacing-xs $az-spacing-4xs $az-spacing-4xs $az-spacing-xs;
  width: $az-percent-100;
  display: flex;
  flex-direction: column;
}

.errorPageFlexStart {
  align-self: flex-start;
}

.errorPageImg {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 315px;
  width: $az-percent-100;
  background-image: url('https://www.autozone.com/cdn/images/B2C/US/media/Landing/Error/errorpage-light-m.jpg');
  align-items: flex-end;
  margin-top: $az-spacing-xxs;
}

.positionAbsolute {
  @media #{$medium-and-up} {
    position: absolute;
  }
}

.errorPageBtn {
  margin-top: $az-spacing-m;

  @media #{$small-and-down} {
    margin-bottom: $az-spacing-m;
    margin-top: $az-spacing-xs;
  }

  button {
    font-family: $font-az-cond;
    width: $az-spacing-170;
    height: $az-spacing-50;
    padding: $az-spacing-xxs $az-spacing-4xs;
  }

  button:active {
    background: $az-dark-orange-2;
  }

  button:hover {
    background: $az-dark-orange-2;
  }

  button:focus {
    background: $az-dark-orange-2;
  }
}

// TABLET AND DESKTOP

.marginTopZero {
  @media #{$medium-only} {
    margin-top: $az-spacing-zero !important;
  }
}

.marginBottom30 {
  @media #{$medium-and-up} {
    margin-bottom: $az-spacing-30;
  }
}

.paragraphContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  @media #{$extra-large-and-up} {
    padding-top: $az-spacing-14;
  }
}

.paragraphContainer > p {
  padding-bottom: $az-spacing-4xs;
  @media #{$extra-large-and-up} {
    padding-bottom: $az-spacing-zero;
  }
}

.errorPageContainerLarge {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  @media #{$large-and-up} {
    padding-left: $az-spacing-66;
    height: $az-spacing-480;
    max-width: 1504px;
    background-image: url('https://www.autozone.com/cdn/images/B2C/US/media/Landing/Error/errorpage-light-d.jpg');
  }

  @media #{$medium-only} {
    height: $az-spacing-400;
    padding-left: $az-spacing-xs;
    width: $az-spacing-688;
    background-position: center;
    background-image: url('https://www.autozone.com/cdn/images/B2C/US/media/Landing/Error/errorpage-light-t.jpg');
  }

  h1 {
    @media #{$large-and-up} {
      font-family: $font-az-cond-heavy;
      font-size: $az-spacing-50;
      height: $az-spacing-30;
    }

    @media #{$extra-large-and-down} {
      font-family: $font-az-cond-heavy;
      font-size: $font-size-45;
      height: $az-spacing-m;
    }
  }

  .errorPageParagraph {
    @media #{$medium-and-up} {
      font-size: $az-spacing-xs;
      max-width: 383px;
      margin-top: $az-spacing-10;
      margin-bottom: $az-spacing-4xs;
      line-height: $az-spacing-26;
    }

    @media #{$medium-only} {
      font-size: $az-spacing-xxs;
      max-width: 348px;
      margin-top: $az-spacing-15;
      line-height: $az-spacing-22;
    }

    @media #{$small-and-down} {
      font-size: $az-spacing-14;
      max-width: 161px;
      margin: $az-spacing-xs $az-spacing-13 $az-spacing-zero $az-spacing-zero;
      color: white;
      text-align: right;
      line-height: $az-spacing-19;
    }
  }
}

.errorPageElements {
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: space-evenly;

  @media #{$medium-and-up} {
    height: $az-percent-50;
    width: $az-percent-100;
    margin: auto;
  }
  @media #{$medium-only} {
    height: $az-percent-63;
  }
  @media #{$extra-large-and-up} {
    margin-top: $az-spacing-84;
  }
}
