@import '@/theme/newVariables';

.dealDrawerConfirmation {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dealDrawerConfirmationScroller {
  height: 100%;
  overflow: auto;
}

.dealDrawerConfirmationBody {
  padding: 16px;
  flex: 1 1 auto;
}

.dealDrawerConfirmationHeader {
  padding: 16px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}

.section {
  margin-bottom: 16px;
}

.sectionTitle {
  font-size: 16px;
  font-weight: bold;
}

.centered {
  text-align: center;
}

.sectionDescription {
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: 12px;
}

.dealDrawerConfirmtionFooter {
  padding: 12px;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  width: 100%;
  align-items: flex-end;
}

.dealDrawerGoToCartButton {
  button {
    width: 100%;
    text-transform: uppercase;

    &:focus {
      background-color: $az-dark-orange-2;
    }

    &:hover {
      background-color: $az-dark-orange-2;
    }
  }
}
