@import '../../../theme/newVariables.scss';

.corePrice {
  color: #6e6e6e;
  flex: 1;
  margin: $az-spacing-4xs $az-spacing-zero $az-spacing-xxs;
  font-style: italic;
  @media #{$small-and-down} {
    font-size: $font-size-10;
  }
  &.newFulfillmentCorePrice {
    margin-bottom: 0;
  }
  .coreDepositInfo {
    width: $az-spacing-xxxs;
    height: $az-spacing-xxxs;
    margin-left: $az-spacing-3;
  }
}

.corePriceCompImg {
  width: $az-spacing-xxxs;
  height: $az-spacing-xxxs;
}
