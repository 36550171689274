@import '../../../theme/newVariables.scss';

.warningMessage {
  display: flex;
  margin: $az-spacing-27 0;

  .warningTextStyles {
    font-family: $font-az-helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-left: 10px;
  }
}
div.warningTextCart {
  & > div > span {
    font-size: $font-size-14;
  }
}
