@import '@/theme/newVariables.scss';

.badgeWrapper {
  display: flex;
}

.orangeBanner {
  width: $az-spacing-67;
  height: $az-spacing-s;
  background-color: $az-medium-orange;
  color: $az-white;
  font-size: $font-size-10;
  font-family: $font-az-helvetica-heavy;
  display: flex;
  align-items: center;
  justify-content: center;
}

.corner {
  width: $az-spacing-zero;
  height: $az-spacing-zero;
  border-top: $az-spacing-s solid $az-medium-orange;
  border-right: $az-spacing-4xs solid $az-white;
}

.badgeOfferContent {
  color: $az-grey-6;

  @media #{$small-and-down} {
    margin-top: $az-spacing-4xs;
  }
}

.instantSaving {
  padding: $az-spacing-zero;
  margin: $az-spacing-zero;
  color: $az-grey-4;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

.saveBadgeWrapper {
  display: flex;
  align-items: center;

  @media #{$small-and-down} {
    flex-direction: column;
    align-items: flex-start;
  }

  @media #{$extra-large-and-up} {
    margin-bottom: $az-spacing-5xs;
  }
}
