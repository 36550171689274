@import '@/theme/newVariables';

.carouselContent {
  margin-bottom: $az-spacing-4xs;
  width: $az-percent-100;
  flex: 1;

  @media #{$extra-large-and-up} {
    overflow: auto;
  }

  :global {
    .slider {
      outline: none;
    }
  }
}

.videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselControl {
  @media #{$small-and-down} {
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      background-color: unset;
    }
  }
}

.videoIframe {
  width: $az-percent-100;
  max-height: 100%;

  @media only screen and (max-width: 375px) {
    width: $az-percent-100;
    height: $az-spacing-200;
  }

  @media only screen and (min-width: 376px) and (max-width: 414px) {
    height: $az-spacing-215;
  }

  @media #{$small-and-down} {
    width: calc(#{$az-percent-100} - #{$az-spacing-50});
  }

  @media #{$xsmall-and-down} {
    height: $az-spacing-162;
  }
}
