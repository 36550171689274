@import '../../theme/newVariables.scss';

caption.root {
  color: $az-black;
  text-align: $ldirection;
  padding: $az-percent-zero;

  &.topCaption {
    caption-side: $tdirection;
  }
}
