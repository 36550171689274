@import '../../../theme/newVariables.scss';

.resetMarginTop {
  margin-top: -$az-spacing-xxxs; // minus margin used here to override material-ui radio button padding added for special effect
}

.toggleContainer {
  width: $az-percent-100;

  .segmentedToggleContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: inset $az-spacing-neg-1 $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-white;
    background-color: $az-grey-1;
    padding-bottom: $az-spacing-5xs;
    padding-top: $az-spacing-5xs;

    .segmentedToggle {
      height: auto;
      padding: $az-spacing-xxs;
      margin: $az-spacing-5xs $az-spacing-4xs;
      background-color: $az-white;
      border-radius: $az-spacing-5xs;
      box-shadow: $az-box-shadow-gray-6;
    }

    .rootToggleButton {
      background-color: $az-grey-1;

      &:hover {
        box-shadow: $az-box-shadow-gray-4;
        background-color: $az-grey-2;
      }
    }

    .selectedToggleButton {
      background-color: $az-white;
      box-shadow: $az-box-shadow-gray-4;

      &:hover {
        background-color: $az-grey-2;
      }
    }

    .labelToggleButton {
      justify-content: flex-start;
    }
  }

  .segmentedBopusMXContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: $az-grey-1;
    padding-bottom: $az-spacing-5xs;
    padding-top: $az-spacing-5xs;

    .segmentedToggle,
    .inlineToggle {
      height: auto;
      padding: $az-spacing-4xs;
      margin: $az-spacing-5xs $az-spacing-4xs;
      border-radius: $az-spacing-5xs;
      display: grid;
      grid-template-columns: 0fr 1fr;
      gap: 16px;
      align-items: center;
    }

    .rootToggleButton {
      background-color: $az-grey-1;

      &:hover {
        box-shadow: $az-box-shadow-gray-4;
        background-color: $az-grey-2;
      }
    }

    .selectedToggleButton {
      background-color: $az-white;
      box-shadow: $az-box-shadow-gray-4;

      &:hover {
        background-color: $az-grey-2;
      }
    }

    .labelToggleButton {
      justify-content: flex-start;
    }
  }

  .inlineToggleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    box-shadow: inset $az-spacing-neg-1 $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-white;
    background-color: $az-grey-1;
    padding-left: $az-spacing-5xs;
    padding-right: $az-spacing-5xs;

    @media #{$small-and-down} {
      flex-direction: column;
      padding-right: $az-spacing-xxxs;
      padding-bottom: $az-spacing-5xs;
    }

    .inlineToggle {
      height: auto;
      width: $az-percent-100;
      padding: $az-spacing-xxs;
      margin: $az-spacing-4xs $az-spacing-5xs;
      cursor: pointer;

      @media #{$small-and-down} {
        margin-bottom: $az-spacing-5xs;
      }

      background-color: $az-white;
      border-radius: $az-spacing-5xs;
      box-shadow: $az-box-shadow-gray-6;

      &:hover {
        background-color: $az-white;
      }

      .labelToggleButton {
        justify-content: flex-start;
      }
    }
  }

  .inStock {
    color: $az-color-success;
    font-weight: bold;
  }

  .notAvailable {
    color: $az-color-error;
  }

  .notAvailableMX {
    color: #d52b1e;
  }

  .notAvailableAtStore {
    margin-left: $az-spacing-3;
  }

  .storeAddressLink {
    text-align: left;
  }

  .storeAddressBopusMX {
    font-family: $font-az-helvetica;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-decoration: #484848 underline;
    text-transform: capitalize;
    color: #484848;
  }

  .inStockSisterStoreLabel {
    color: green;
    font-weight: bold;
    margin: 0 $az-spacing-5 0 $az-spacing-2;
  }

  .inStockSisterStoreLabelBopusMX {
    color: green;
    font-weight: bold;
  }
  .freeNextDayDelivery {
    font-weight: bold;
    font-style: italic;
    color: $az-color-info-alt;
    font-size: $font-size-10;
  }

  .storeLink {
    color: $az-grey-6;
  }

  .nddInfo {
    display: inline;
    margin-left: $az-spacing-4xs;
    line-height: $az-spacing-zero;
  }

  .bold {
    font-weight: bold;
  }

  .normal {
    font-weight: 400;
    color: #484848;
    text-decoration: #484848 underline;
  }

  .deliveryOptionLabel {
    font-weight: bold;
    font-size: $font-size-12;
    color: $az-grey-6;
    margin-bottom: $az-spacing-5;
  }

  .deliveryOptionLabelBopusMX {
    font-weight: bold;
    font-size: $font-size-12;
    color: #202124;
  }
  .selectStore {
    margin-top: 2px;
  }
}

.aditionalCost {
  color: #5b5d5b;
  font-size: $font-size-12;
  font-weight: bold;
  font-family: $font-az-helvetica;
  // text-transform: capitalize;
}
.vdpPhoneLink {
  color: $az-color-info;
  font-size: $font-size-12;
  font-family: $font-az-helvetica;
  text-decoration: underline;
  // text-transform: capitalize;
  vertical-align: 2px;
}
.infoToolTipWrapper {
  color: $az-color-info;
  width: 14px !important;
  height: 14px !important;
}
.vpdItemCard {
  width: 100%;
  height: auto;
  // padding: $az-spacing-xxs;
  padding: $az-spacing-5xs $az-spacing-4xs;
  // background-color: $az-white;
  border-radius: $az-spacing-5xs;
  // box-shadow: $az-box-shadow-gray-6;
}
.vdpItemDisplay {
  display: flex;
  padding: 16px 0;
  & img {
    margin-right: 16px;
  }
}
.vdpPriceNotAvailable {
  font-size: 12px;
  color: #202124;
}
.paddingForSTLS {
  padding: 8px;
}
